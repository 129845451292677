import React, { useEffect } from "react";
import "./Billing.css";
import RazorpayForm from "./RazorpayForm";
import { connctDB } from "../ConnctDB";
import { useLiveQuery } from "dexie-react-hooks";
import { useLocationContext } from "./LocationInterface";

const Billing = () => {
  const cartItems = useLiveQuery(() => connctDB.cart.toArray());

  const { priceType, isIndia } = useLocationContext();

  // Calculate subtotal, shipping, GST, and total
  const subtotal = cartItems?.reduce((acc, item) => {
    return acc + item[priceType] * item.addedQuantity;
  }, 0);
  const shippingHandling = 0; // Set the shipping and handling fee to 0
  const gstPerProduct = isIndia ? 359.82 : 4.31; // Fixed GST amount for a single product
  const totalQty = cartItems?.reduce(
    (acc, item) => acc + item.addedQuantity,
    0
  ); // Total quantity of all products in the cart
  const gst = gstPerProduct * totalQty; // Calculate GST based on the total quantity
  const total = subtotal + shippingHandling + gst;

  return (
    <div className="wrapper" style={{ marginTop: "150px" }}>
      <div className="container">
        <section className="shopping-billing">
          <div className="row pb-3">
            <div className="col-md-6">
              <h3>Billing Details</h3>
            </div>
            <div className="col-md-6 d-flex justify-content-end">
              <a href="/product-list" className="btn btn-secondary">
                Back to shopping
              </a>
            </div>
          </div>
          <div>
            <h5>Shipping Address</h5>
          </div>
          <RazorpayForm
            total={total}
            cartItems={cartItems}
            subtotal={subtotal}
            shippingHandling={shippingHandling}
            gst={gst}
          />
        </section>
      </div>
    </div>
  );
};

export default Billing;
