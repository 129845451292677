import React, { useState, useEffect, Suspense } from "react";
import axios from "axios";
import "./Blogs.css";
import { Link } from "react-router-dom";
import { FaCalendarDay } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const [latestBlogs, setLatestBlogs] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const getDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    const animateBlogs = () => {
      const blogItems = document.querySelectorAll(".blog-item");

      blogItems.forEach((item, index) => {
        setTimeout(() => {
          item.classList.add("visible");
        }, index * 100); // Adjust the delay as needed
      });
    };
    // Call animateBlogs after the blogs have been set
    animateBlogs();
  }, [blogs]);

  const AWS_S3_BUCKET_URL = process.env.REACT_APP_AWS_S3_BUCKET_URL;
  const fetchBlogs = async () => {
    const categories = [];
    const blogsArray = [];
    // Fetch blogs from the API when the component mounts
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/v1/api/public-api/category-with-blogs`
    );
    const getLatesBlogs = response.data.getLatestBlogs;
    const categoryWithBlogs = response.data.categoryWithBlogs;
    categoryWithBlogs.forEach((blog) => {
      if (blog.Blogs?.length > 0) categories.push(blog.id);
    });
    setLatestBlogs(getLatesBlogs);
    setIsLoading(false);

    for (const category of categories) {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/public-api/category-with-blogs-by-id/${category}`
      );
      const categoryWithBlogs = response.data.categoryWithBlogs;
      blogsArray.push(categoryWithBlogs);
      setIsLoading(false);
    }
    setBlogs(blogsArray);
    return;
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }
  function capitalizeFirstLetter(string) {
    if (!string) {
      return ""; // Handle cases where the string is empty or undefined
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div className="container" style={{ marginTop: "100px" }}>
      <Helmet>
        <title>
          Networking &amp; Event Marketing Blog. Expert Tips &amp; Strategies!
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="facebook-domain-verification"
          content="76mxtqr8maf4jd7publr59pn5stjur"
        ></meta>
        <meta
          name="google-site-verification"
          content="VS9tF3UvpS-8c0O9z1jclypU0SBa_rFQKlzDZ5TmEKM"
        ></meta>
        <meta
          name="description"
          content="Upgrade your networking with Connct.ai's Digital NFC Business Cards. Stay connected effortlessly and make lasting impressions."
        ></meta>
        <meta
          name="title"
          content="Contact the Best Digital Business Card Provider | Connct"
        ></meta>
        <meta
          name="description"
          content="Contact Connct for effortless digital networking. Elevate connections with NFC-enabled business cards. Tap and go for seamless networking!"
        ></meta>
        <meta
          name="title"
          content="Networking &amp; Event Marketing Blog. Expert Tips &amp; Strategies!"
        ></meta>
        <meta
          name="description"
          content="Stay ahead in networking and event marketing with our expert tips &amp; strategies. Boost your skills and level up your success!"
        ></meta>
      </Helmet>
      <div className="latest-blogs">
        <Link
          className="latest-blogs-first blog-item"
          to={`/blogs/${latestBlogs[0].slug}`}
          style={{ borderRadius: "10px" }}
        >
          <div className="zoom-effect">
            <img
              alt=""
              className="img-fluid"
              src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${latestBlogs[0].image}`}
              width={"20%"}
              style={{ borderRadius: "10px" }}
            ></img>
          </div>
          <br />
          <h5>{latestBlogs[0].title}</h5>
          <div className="date">
            <FaCalendarDay style={{ marginLeft: 0 }} />
            <div style={{ lineHeight: "2em" }}>
              &nbsp;{getDate(latestBlogs[0].createdAt)}
            </div>
          </div>
          <div className="description">{latestBlogs[0].metaDesc}</div>
          <div className="category-tag">
            <span style={{ color: "red" }}>
              {capitalizeFirstLetter(latestBlogs[0].Category.name)}
            </span>{" "}
            <span style={{ color: "green" }}>
              {capitalizeFirstLetter(latestBlogs[0]?.Subcategory?.name)}
            </span>
          </div>
        </Link>

        <div>
          {latestBlogs?.map(
            (latestBlog, index) =>
              index > 0 && (
                <Link
                  key={index}
                  className="latest-blog-item blog-item"
                  to={`/blogs/${latestBlog.slug}`}
                  style={{ borderRadius: "10px" }}
                >
                  <div className="zoom-effect">
                    <img
                      alt=""
                      className="img-fluid"
                      src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${latestBlog.image}`}
                      style={{ borderRadius: "10px" }}
                    ></img>
                  </div>
                  <br />
                  <div className="latest-blog-item-data">
                    <h5>{latestBlog.title}</h5>
                    <div className="date">
                      <FaCalendarDay style={{ marginLeft: 0 }} />
                      <div style={{ lineHeight: "2em" }}>
                        {getDate(latestBlog.createdAt)}{" "}
                        {/* Use createdAt instead of updatedAt */}
                      </div>
                    </div>
                    <div className="description">{latestBlog.metaDesc}</div>
                    <div className="category-tag">
                      <span style={{ color: "red" }}>
                        {latestBlog.Category.name}
                      </span>{" "}
                      <span style={{ color: "green" }}>
                        {latestBlog?.Subcategory?.name}
                      </span>
                    </div>
                  </div>
                </Link>
              )
          )}
        </div>
      </div>

      <div className="blog-grid">
        {blogs.map((category) =>
          category.Blogs.map((blog, blogIndex) => (
            <Suspense key={blogIndex} fallback={<p>Loading...</p>}>
              <div
                className="blog-item"
                key={blogIndex}
                style={{ borderRadius: "7px" }}
              >
                <Link to={`/blogs/${blog.slug}`}>
                  <img
                    alt=""
                    className={`img-fluid zoom-effect`}
                    src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${blog.image}`}
                    style={{ borderRadius: "10px" }}
                  ></img>
                  <br />
                  <h5>{blog.title}</h5>
                  <div className="date">
                    <FaCalendarDay style={{ marginLeft: 0 }} />
                    <div style={{ lineHeight: "2em" }}>
                      {getDate(blog.updatedAt)}
                    </div>
                  </div>
                  <div className="description">{blog.metaDesc}</div>
                  <div className="category-tag">
                    <span style={{ color: "red" }}>
                      {category.name.charAt(0).toUpperCase() +
                        category.name.slice(1)}
                    </span>{" "}
                    <span style={{ color: "green" }}>
                      {blog?.Subcategory?.name
                        ? blog.Subcategory.name.charAt(0).toUpperCase() +
                          blog.Subcategory.name.slice(1)
                        : ""}
                    </span>
                  </div>
                </Link>
              </div>
            </Suspense>
          ))
        )}
      </div>
    </div>
  );
};

export default Blogs;
