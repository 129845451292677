import React from 'react';
import './Privacy.css';

const ShippingPolicy = () => {
  return (
    <div className="wrapper" style={{marginTop:'130px'}}>
      <section className="section">
        <div className="container">
          <div className="page-header">
            <h2>Shipping Policy</h2>
            <hr className="lightgrey-line" /> 
          </div>

          <p style={{fontSize:'16px'}}>
          This Refund Policy describes how we manage refunds.
          </p>
    <h4>Thank you for choosing Connct! We are committed to delivering your order
                  promptly and efficiently. Please review our shipping policy below for details:</h4>
    <h5>Shipping Methods:</h5>
    <p style={{fontSize:'16px'}}>We offer standard shipping for all orders, which
                  typically takes 3-5 business days for delivery across India. Expedited
                  shipping options may be available for an additional fee.
                  International shipment will take 10 business days for delivery from the date
                  of Purchase</p>

    <h4 >Order Processing Time:</h4>
    <p style={{fontSize:'16px'}}>Orders are processed within 1-2 business days
                  from the date of purchase. Please note that orders placed on weekends or
                  holidays will be processed on the next business day.</p>
    <h4> Shipping Rates:</h4>
    <p style={{fontSize:'16px'}}>Shipping rates are calculated based on the total weight of
                  the order and the shipping destination. Shipping costs will be displayed
                  during the checkout process before payment is made.</p>
    <h4>Order Tracking:</h4>
    <p style={{fontSize:'16px'}}>Once your order has been shipped, you will receive a
                  shipping confirmation email containing tracking information. You can use this
                  tracking number to monitor the status of your shipment.</p>
    <h4>Delivery Issues:</h4>
    <p style={{fontSize:'16px'}}>If you encounter any issues with your delivery, such as
                  delays or damaged items, please contact us immediately. We will work
                  diligently to resolve any problems and ensure that you receive your order
                  promptly.</p>
   
    <h5 > Questions or Concerns:</h5>
    <p style={{fontSize:'16px'}}>If you have any questions or concerns about our
                  shipping policy, please don't hesitate to contact us. Our customer service
                  team is available to assist you and address any inquiries you may have.
                  Thank you for choosing Connct!</p>
   <br/><br/>
        </div>
      </section>
    </div>
  );
}

export default ShippingPolicy;
