import React from 'react';
import './Privacy.css';

const Refund = () => {
  return (
    <div className="wrapper" style={{marginTop:'130px'}}>
      <section className="section">
        <div className="container">
          <div className="page-header">
            <h2>Refund Policy</h2>
            <hr className="lightgrey-line" /> 
          </div>

          <p style={{fontSize:'16px'}}>
          This Refund Policy describes how we manage refunds.
          </p>
    <h4>Returns</h4>
    <p style={{fontSize:'16px'}}>We do not take returns. To be eligible for a refund, we require a receipt or proof of purchase in the form of an order number and your order must have not been fulfilled, shipped or delivered per carrier tracking information</p>
    <h5>Refunds (if applicable)</h5>
    <p style={{fontSize:'16px'}}>If your Connct product is scratched or damaged, you may be eligible for a replacement. We offer replacements for damaged Connct products with photo proof within 48 hours of delivery.<br/>
<br/>If you are having trouble activating your Connct product or connecting to compatible phones and you would like to request a refund or replacement, please contact our team at support@connct.ai. Typically, all issues can be resolved with some helpful tips from the team!<br/><br/>If you have successfully activated your Connct product and are experiencing issues connecting to a compatible device after you’ve contacted support@connct.ai, you may be eligible for a refund. Once approved for a refund, we will send you an email notifying you that we have refunded your purchase. Your refund will then be processed, and a credit will automatically be applied to your credit card or original method of payment, within 2-4 days.</p>

    <h4 >Late or missing refunds (if applicable)</h4>
    <p style={{fontSize:'16px'}}>If you haven’t received a refund yet, first check your bank account again. Next, contact your bank. There is often some processing time before a refund is posted. If you’ve done all of this and you still have not received your refund yet, please contact us at support@connct.ai.</p>
    <h4>Sale items</h4>
    <p style={{fontSize:'16px'}}>Only regular priced items may be refunded (if applicable); unfortunately, sale items cannot be refunded.</p>
    <h4>Exchanges</h4>
    <p style={{fontSize:'16px'}}>We do not accept exchanges.</p>
    <h4>Shipping</h4>
    <p style={{fontSize:'16px'}}>We do not accept returns, so no shipping information is required.</p>
   
    <h5 >Contact us</h5>
    <p style={{fontSize:'16px'}}>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by email at support@connct.ai</p>
   <br/><br/>
        </div>
      </section>
    </div>
  );
}

export default Refund;
