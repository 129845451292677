import React, { createContext, useContext, useEffect, useState } from "react";

const InterfaceContext = createContext();

const LocationInterface = ({ children }) => {
  const [isIndia, setIsIndia] = useState(true);
  const [currencySymbol, setCurrencySymbol] = useState("₹");
  const [priceType, setPriceType] = useState("price");

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (timeZone !== "Asia/Calcutta") {
      setIsIndia(false);
      setCurrencySymbol("$");
      setPriceType("iprice");
    } else {
      setIsIndia(true);
      setCurrencySymbol("₹");
      setPriceType("price");
    }
  }, []);

  return (
    <InterfaceContext.Provider value={{ isIndia, currencySymbol, priceType }}>
      {children}
    </InterfaceContext.Provider>
  );
};

export const useLocationContext = () => useContext(InterfaceContext);

export default LocationInterface;
