import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";

const ConfirmPassword = () => {
  const { id } = useParams();
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [showPasswordError, setShowPasswordError] = useState(false);
  const [showCPasswordError, setShowCPasswordError] = useState(false);

  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const verticalLineStyle = {
    borderLeft: "2px solid lightgray",
    height: "80%",
    position: "absolute",
    left: "-10px",
  };

  useEffect(() => {
    setShowPasswordError(false);
    setShowCPasswordError(false);
    if (password === "") {
      return setShowPasswordError(true);
    } else if (cpassword === "" || cpassword !== password) {
      return setShowCPasswordError(true);
    }
  }, [password, cpassword]);

  let notifyStatus;
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (showPasswordError || showCPasswordError) {
        return;
      } else {
        notifyStatus = toast.loading("Processing...", {
          closeButton: "false",
          isLoading: "true",
        });
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/v1/api/auth/reset-password`,
          { password, cpassword, token: id }
        );

        if (response.status === 200) {
          // Show a success toast message
          toast.update(notifyStatus, {
            render: "Success! Reset password email sent to your email",
            type: "success",
            closeButton: true,
            isLoading: false,
            autoClose: 1000,
          });
          // toast.success(
          //   "Success! Reset password email sent to your email successfully",
          //   {
          //     position: toast.POSITION.TOP_CENTER,
          //   }
          // );
          navigate("/login", { state: { fromConfirmPassword: true } });
        }
      }
    } catch (error) {
      // Handle any errors, e.g., display an error message to the user
      toast.update(notifyStatus, {
        render:
          "Error! There was an issue sending the reset link. Please try again later.",
        type: "error",
        closeButton: true,
        isLoading: false,
        autoClose: 1000,
      });
      // toast.error(
      //   "Error! There was an issue sending the reset link. Please try again later.",
      //   {
      //     position: toast.POSITION.TOP_CENTER,
      //   }
      // );
    }
  };

  return (
    <div className="wrapper" style={{ marginTop: "100px" }}>
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              className="header-logo img-fluid"
              src={require("../assets/images/logos.png")}
              alt="Logo"
              style={{ maxWidth: "170px" }}
            />
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/faq"
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    fontWeight: "normal",
                  }}
                >
                  {!isMenuOpen && <span style={verticalLineStyle}></span>}
                  Need any help?
                </a>
              </li>
              {/* Add more menu items here */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container">
        <section className="login">
          <div className="row align-items-center">
            <div className="col-sm-6 login-left d-none d-sm-block">
              <img
                src={require("../assets/images/login.jpg")}
                className="img-fluid d-block mx-auto login-image"
                alt="Login"
                style={{ height: "500px" }}
              />
            </div>

            <div className="col-sm-6 login-right">
              <div className="login-account">
                <a href="/">
                  <img
                    src={require("../assets/images/logo-icon.png")}
                    className="footer-logo d-block mx-auto mt-5"
                    alt="Logo Icon"
                    style={{ maxHeight: "100%", width: "auto" }}
                  />
                </a>
                <h2>Reset Password</h2>

                <form>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    {showPasswordError && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        Password is Required
                      </div>
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="cpassword" className="form-label">
                      Confirm password
                    </label>
                    <input
                      id="cpassword"
                      type="password"
                      className="form-control"
                      placeholder="Confirm Password"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={cpassword}
                      onChange={(e) => setCPassword(e.target.value)}
                    />
                    {showCPasswordError && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        Password do not match
                      </div>
                    )}
                  </div>
                  <button
                    id="login-button"
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ConfirmPassword;
