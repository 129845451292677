import React, { useState } from "react";
import axios from "axios";
import "./Login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Forgotpassword = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  const verticalLineStyle = {
    borderLeft: "2px solid lightgray",
    height: "80%",
    position: "absolute",
    left: "-10px",
  };

  let notifyStatus;

  const handleSendMail = async (e) => {
    e.preventDefault();

    const emailInput = document.getElementById("email");
    const email = emailInput.value;

    // Check if the email is empty
    if (!email) {
      // Show an error toast message
      toast.update(notifyStatus, {
        render: "Error! Please enter your email address.",
        type: "error",
        closeButton: true,
        isLoading: false,
        autoClose: 1000,
      });
      toast.update("Error! Please enter your email address.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return; // Don't proceed with the API call if email is empty
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/v1/api/auth/forgot-password`,
        { email }
      );

      if (response.status === 200) {
        // Show a success toast message
        toast.update(notifyStatus, {
          render:
            "Success! Reset password email sent to your email successfully",
          type: "success",
          closeButton: true,
          isLoading: false,
          autoClose: 1000,
        });
        toast.success(
          "Success! Reset password email sent to your email successfully",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      }

      emailInput.value = ""; // Clear the input field value after successful submission
    } catch (error) {
      // Handle any errors, e.g., display an error message to the user
      toast.update(notifyStatus, {
        render:
          "Error! There was an issue sending the reset link. Please try again later.",
        type: "error",
        closeButton: true,
        isLoading: false,
        autoClose: 1000,
      });
      // toast.error(
      //   "Error! There was an issue sending the reset link. Please try again later.",
      //   {
      //     position: toast.POSITION.TOP_CENTER,
      //   }
      // );
    }
  };

  return (
    <div className="wrapper" style={{ marginTop: "100px" }}>
      <nav className="navbar navbar-expand-lg fixed">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img
              className="header-logo img-fluid"
              src={require("../assets/images/logos.png")}
              alt="Logo"
              style={{ maxWidth: "170px" }}
            />
          </a>
          <button
            className={`navbar-toggler ${isMenuOpen ? "collapsed" : ""}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isMenuOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="/faq"
                  style={{
                    fontSize: "16px",
                    position: "relative",
                    fontWeight: "normal",
                  }}
                >
                  {!isMenuOpen && <span style={verticalLineStyle}></span>}
                  Need any help?
                </a>
              </li>
              {/* Add more menu items here */}
            </ul>
          </div>
        </div>
      </nav>

      <div className="container">
        <section className="login">
          <div className="row align-items-center">
            <div className="col-sm-6 login-left d-none d-sm-block">
              <img
                src={require("../assets/images/login.jpg")}
                className="img-fluid d-block mx-auto login-image"
                alt="Login"
                style={{ height: "500px" }}
              />
            </div>

            <div className="col-sm-6 login-right">
              <div className="login-account">
                <a href="/">
                  <img
                    src={require("../assets/images/logo-icon.png")}
                    className="footer-logo d-block mx-auto mt-5"
                    alt="Logo Icon"
                    style={{ maxHeight: "100%", width: "auto" }}
                  />
                </a>
                <h2>Forgot Password</h2>
                <h3>No worries, we got your back</h3>
                <form>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email address
                    </label>
                    <input
                      id="email"
                      type="text"
                      className="form-control"
                      placeholder="Email Address"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <button
                    id="login-button"
                    className="btn btn-primary"
                    type="submit"
                    onClick={handleSendMail}
                  >
                    Send Mail
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Forgotpassword;
