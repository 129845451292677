import React from 'react';

const Compatible = () => {
    return (
        <section className="section">
            <div className="container mt-5" >
                <div className="page-header" style={{marginTop:'34px'}}>
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb" style={{ whiteSpace: 'nowrap' }}>
  <li className="breadcrumb-item" style={{ display: 'inline-block', fontSize: '11px' }}>
    <a href="/" style={{ fontSize: '11px' }}>Home</a>
  </li>
  <li className="breadcrumb-item active" aria-current="page" style={{ display: 'inline-block', fontWeight: 'bold', color: 'black', fontSize: '11px' }}>
    Devices
  </li>
</ol>

                        <h2 style={{fontWeight:'bold'}}>Compatible Devices</h2>
                    <p style={{color:'black',fontSize:'18px'}}>We support most of the standard modern smartphones.</p>
                  <br/>  </nav>
                </div><br/>
                <div style={{ border: '1px solid lightgray', padding: '20px',borderRadius:'10px' }}>
                <h2 style={{color:'#01459D',fontWeight:'bold'}}>iOS Devices</h2>
                    <hr style={{ borderTop: '1px solid gray' }} />
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Apple</h5>
                <div class="col-md-3">iphone 7</div>
                <div class="col-md-3">iphone 7 plus</div>
                <div class="col-md-3">iphone 8</div>
                <div class="col-md-3">iphone 8 plus</div>
                <div class="col-md-3">iphone X</div>
                <div class="col-md-3">iphone XS</div>
                <div class="col-md-3">iphone XS Max</div>
                <div class="col-md-3">iphone XR</div>
                <div class="col-md-3">iphone SE (2020)</div>
                <div class="col-md-3">iphone 11</div>
                <div class="col-md-3">iphone 11 pro</div>
                <div class="col-md-3">iphone 12</div>
                <div class="col-md-3">iphone 12 mini</div>
                <div class="col-md-3">iphone 12 pro</div>
                <div class="col-md-3">iphone 12 pro Max</div>
                <div class="col-md-3">iphone 13</div>
                <div class="col-md-3">iphone 13 mini</div>
                <div class="col-md-3">iphone 13 pro</div>
                <div class="col-md-3">iphone 13 pro Max</div>
            </div>
                </div><br/>
                <div style={{ border: '1px solid lightgray', padding: '20px',borderRadius:'10px' }}>
                    <h2 style={{color:'#01459D',fontWeight:'bold'}}>Android</h2>
                    <hr style={{ borderTop: '1px solid gray' }} />
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Samsung</h5>
                <div class="col-md-3">Galaxy SIII</div>
                <div class="col-md-3">Galaxy S4</div>
                <div class="col-md-3">Galaxy S5</div>
                <div class="col-md-3">Galaxy S6</div>
                <div class="col-md-3">Galaxy S6 Edge</div>
                <div class="col-md-3">Galaxy S6 Edge+</div>
                <div class="col-md-3">Galaxy S7</div>
                <div class="col-md-3">Galaxy S7 Edge</div>
                <div class="col-md-3">Galaxy S8</div>
                <div class="col-md-3">Galaxy S8+</div>
                <div class="col-md-3">Galaxy S9</div>
                <div class="col-md-3">Galaxy S9+</div>
                <div class="col-md-3">Galaxy S9 Plus</div>
                <div class="col-md-3">Galaxy S10</div>
                <div class="col-md-3">Galaxy S10 Plus</div>
                <div class="col-md-3">Galaxy S21 Plus</div>
                <div class="col-md-3">Galaxy S20 Plus</div>
                <div class="col-md-3">Galaxy S20 Ultra</div>
                <div class="col-md-3">Galaxy S21 Ultra</div>
                <div class="col-md-3">Galaxy S20 FE</div>
                <div class="col-md-3">Galaxy Note 3</div>
                <div class="col-md-3">Galaxy Note 3 NEO</div>
                <div class="col-md-3">Note 5</div>
                <div class="col-md-3">Note 5</div>
                <div class="col-md-3">Galaxy Note 10 Lite</div>
                <div class="col-md-3">Galaxy Note 10 Plus</div>
                <div class="col-md-3">Galaxy Alpha</div>
                <div class="col-md-3">Galaxy A32</div>
                <div class="col-md-3">Galaxy A30s</div>
                <div class="col-md-3">Galaxy A5</div>
                <div class="col-md-3">Galaxy A50s</div>
                <div class="col-md-3">Galaxy A51</div>
                <div class="col-md-3">Galaxy A52s</div>
                <div class="col-md-3">Galaxy A52</div>
                <div class="col-md-3">Galaxy A70</div>
                <div class="col-md-3">Galaxy A70s</div>
                <div class="col-md-3">Galaxy A72</div>
                <div class="col-md-3">Galaxy A80</div>
                <div class="col-md-3">Galaxy M32</div>
                <div class="col-md-3"> Galaxy M40</div>
                <div class="col-md-3">Galaxy A50s 6GB RAM</div>
                <div class="col-md-3"> Galaxy M51</div>
                <div class="col-md-3"> Galaxy F62</div>
                <div class="col-md-3">Galaxy On8</div>
                <div class="col-md-3">Galaxy S3 Neo</div>
                <div class="col-md-3">Galaxy Z Flip 3</div>
                <div class="col-md-3">Galaxy Z Fold 3</div>
                <div class="col-md-3">Galaxy M42</div>
            </div>
            
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">OnePlus</h5>
                <div class="col-md-3">OnePlus 3
                </div>
                <div class="col-md-3">OnePlus 3T
                </div>
                <div class="col-md-3">OnePlus 5
                </div>
                <div class="col-md-3"> OnePlus 5T
                </div>
                <div class="col-md-3">OnePlus 6
                </div>
                <div class="col-md-3"> OnePlus 7
                </div>
                <div class="col-md-3">OnePlus 7T
                </div>
                <div class="col-md-3">OnePlus 7 Pro
                </div>
                <div class="col-md-3"> 7T Pro McLaren Edition
                </div>
                <div class="col-md-3"> OnePlus 8 Pro
                </div>
                <div class="col-md-3"> OnePlus 8
                </div>
                <div class="col-md-3">OnePlus Nord
                </div>
                <div class="col-md-3"> OnePlus 8T
                </div>
                <div class="col-md-3">OnePlus 6T
                </div>
                <div class="col-md-3"> OnePlus 9
                </div>
                <div class="col-md-3">OnePlus 9 Pro
                </div>
                <div class="col-md-3"> OnePlus 9 R</div>
            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Nokia</h5>
                <div class="col-md-3">Nokia 3
                </div>
                <div class="col-md-3">Nokia 3.1
                </div>
                <div class="col-md-3"> Nokia 4.2
                </div>
                <div class="col-md-3"> Nokia 5
                </div>
                <div class="col-md-3"> Nokia 5.1
                </div>
                <div class="col-md-3">Nokia 6
                </div>
                <div class="col-md-3"> Nokia 6.1
                </div>
                <div class="col-md-3"> Nokia 7
                </div>
                <div class="col-md-3"> Nokia 7 Plus
                </div>
                <div class="col-md-3">Nokia 8 Sirocco
                </div>
                <div class="col-md-3"> Nokia 8
                </div>
                <div class="col-md-3">Nokia 8.1
                </div>
                <div class="col-md-3"> Nokia 9
                </div>
                <div class="col-md-3"> Nokia Lumia 920
                </div>
                <div class="col-md-3">Nokia Lumia 930</div>
            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Motorola</h5>
                <div class="col-md-3">
                </div>
                <div class="col-md-3">Motorola One
                </div>
                <div class="col-md-3">Motorola One Vision
                </div>
                <div class="col-md-3">Motorola One Action
                </div>
                <div class="col-md-3"> Motorola One Vision Plus
                </div>
                <div class="col-md-3"> Moto Z3 Play
                </div>
                <div class="col-md-3"> Moto E4 Plus
                </div>
                <div class="col-md-3"> Moto X4
                </div>
                <div class="col-md-3"> Moto E5/Plus
                </div>
                <div class="col-md-3"> Moto E5 Play/Go
                </div>
                <div class="col-md-3"> Moto G5/G5S Plus
                </div>
                <div class="col-md-3"> Moto G6/Plus/Play
                </div>
                <div class="col-md-3"> Motorola Razr
                </div>
                <div class="col-md-3"> Motorola Moto Turbo
                </div>
                <div class="col-md-3"> Motorola Moto X Force
                </div>
                <div class="col-md-3"> Motorola Moto X Play
                </div>
                <div class="col-md-3">Motorola Edge S
                </div>
                <div class="col-md-3"> Motorola Edge 20
                </div>
                <div class="col-md-3">Motorola Edge 20 Fusion
                </div>
                <div class="col-md-3">Motorola Edge S Pro
                </div>
                <div class="col-md-3">Motorola Edge 20 Pro
                </div>
                <div class="col-md-3"> Motorola Edge 20 Lite
                </div>
                <div class="col-md-3"> Motorola Edge Plus
                </div>
                <div class="col-md-3">Motorola Edge
                </div>
                <div class="col-md-3"> Motorola Defy
                </div>
                <div class="col-md-3">Motorola Moto X</div>

            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">LG</h5>
                <div class="col-md-3">LG G3
                </div>
                <div class="col-md-3"> LG G4
                </div>
                <div class="col-md-3">LG G5
                </div>
                <div class="col-md-3">LG Nexus 5X
                </div>

                <div class="col-md-3"> LG V10
                </div>
                <div class="col-md-3">LG V20
                </div>
                <div class="col-md-3"> LG V30
                </div>
                <div class="col-md-3">LG V30 ThinQ
                </div>
                <div class="col-md-3"> LG V35 ThinQ
                </div>
                <div class="col-md-3">LG V40 ThinQ
                </div>
                <div class="col-md-3"> LG G6
                </div>
                <div class="col-md-3"> LG Q6
                </div>
                <div class="col-md-3">LG Q6 Plus
                </div>
                <div class="col-md-3"> LG Q Styus
                </div>
                <div class="col-md-3">LG Q Stylus 4
                </div>
                <div class="col-md-3"> LG Q7
                </div>
                <div class="col-md-3">LG G7 ThinQ
                </div>
                <div class="col-md-3"> LG Q8
                </div>
                <div class="col-md-3"> LG G8 ThinQ
                </div>
                <div class="col-md-3"> LG Q92
                </div>
                <div class="col-md-3">LG K10
                </div>
                <div class="col-md-3"> LG K62
                </div>
                <div class="col-md-3">LG K92
                </div>
                <div class="col-md-3"> LG V35 ThinQ
                </div>
                <div class="col-md-3">LG Wing
                </div>
                <div class="col-md-3"> LG G7 Plus ThinQ
                </div>
                <div class="col-md-3"> LG G4 Dual
                </div>
                <div class="col-md-3"> LG G2
                </div>
                <div class="col-md-3"> LG G2
                </div>
                <div class="col-md-3"> LG Stylus 2 Plus
                </div>
                <div class="col-md-3"> LG F70
                </div>

                <div class="col-md-3"> LG G Pro 2
                </div>
                <div class="col-md-3">LG Q7 Plus</div>
            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Xiaomi</h5>
                <div class="col-md-3">
                    Xiaomi Mi 11X
                </div>
                <div class="col-md-3">Xiaomi Mi 11 Lite
                </div>
                <div class="col-md-3"> Xiaomi Mi 10T Pro
                </div>
                <div class="col-md-3"> Xiaomi Mi 10
                </div>
                <div class="col-md-3"> Xiaomi Mi 10T
                </div>
                <div class="col-md-3"> Xiaomi Mi 11X
                </div>
                <div class="col-md-3">Xiaomi Mi 11X Lite
                </div>
                <div class="col-md-3"> Xiaomi Mi3
                </div>
                <div class="col-md-3">Xiaomi Redmi Note 10 Pro
                </div>
                <div class="col-md-3"> Xiaomi Redmi K40
                </div>
                <div class="col-md-3">Xiaomi Mi Mix 4
                </div>
                <div class="col-md-3"> Xiaomi Mi 11T Pro
                </div>
                <div class="col-md-3"> Xiaomi Mi 11 Pro
                </div>
                <div class="col-md-3"> Xiaomi Redmi Note 9 Pro
                </div>
                <div class="col-md-3"> Xiaomi Redmi K40 Pro
                </div>
                <div class="col-md-3">Xiaomi Mi 11 Ultra
                </div>
                <div class="col-md-3">Xiaomi Mi 11 Lite NE
                </div>
                <div class="col-md-3"> Xiaomi Mi 11
                </div>
                <div class="col-md-3"> Xiaomi Mi5
                </div>
                <div class="col-md-3"> Xiaomi Mi 10T Lite
                </div>
                <div class="col-md-3">Xiaomi Redmi K30s
                </div>
                <div class="col-md-3">Xiaomi Mi 11i
                </div>
                <div class="col-md-3">Xiaomi Redmi Note 9T
                </div>
                <div class="col-md-3"> Xiaomi Mi 10 Lite
                </div>
                <div class="col-md-3"> Xiaomi Redmi K40 Pro Plus
                </div>
                <div class="col-md-3">Xiaomi Mi 10S
                </div>
                <div class="col-md-3"> Xiaomi Mi Mix Fold
                </div>
                <div class="col-md-3"> Xiaomi Redmi K30 Ultra
                </div>
                <div class="col-md-3">Xiaomi Mi 10 Ultra
                </div>
                <div class="col-md-3">Xiaomi Mi 11 Lite
                </div>
                <div class="col-md-3">Xiaomi Mi 11 T</div>


            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Google</h5>
                <div class="col-md-3">Google Pixel</div>
                <div class="col-md-3">Google Pixel XL</div>
                <div class="col-md-3">Google Pixel 2</div>
                <div class="col-md-3">
                    Google Pixel 2XL</div>
                <div class="col-md-3">Google Pixel 3XL</div>
                <div class="col-md-3">
                    Google Pixel 3</div>
                <div class="col-md-3">Google Pixel 3A</div>
                <div class="col-md-3">Google Nexus 4
                </div>
                <div class="col-md-3">Google Pixel 4A</div>
                <div class="col-md-3">Google LG Nexus 5</div>
                <div class="col-md-3">Google Nexus 5X</div>
                <div class="col-md-3">Google Nexus 6P</div>
            </div>
                </div>
                <div >
                  
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">REALme</h5>
                <div class="col-md-3">Realme GT</div>
                <div class="col-md-3">Realme Narzo 30</div>
                <div class="col-md-3">Realme X7 Max</div>
                <div class="col-md-3">Realme X7 Pro</div>
                <div class="col-md-3">Realme Narzo 30</div>
                <div class="col-md-3">Realme X50 Pro</div>
                <div class="col-md-3">Realme X7 Max</div>
                <div class="col-md-3">Realme X2 Pro</div>
                <div class="col-md-3">Realme GT Neo</div>

            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Sony</h5>
                <div class="col-md-3">XA1/Ultra/Plus</div>
                <div class="col-md-3">XA2/Ultra/Plus</div>
                <div class="col-md-3">XZ1/Compact</div>
                <div class="col-md-3">XZ2/Compact/Premium</div>
                <div class="col-md-3">Sony Xperia 10</div>
                <div class="col-md-3">Sony Xperia 10 Lite</div>
                <div class="col-md-3">Sony Xperia 8 Lite</div>
                <div class="col-md-3">Sony Xperia XA1</div>
                <div class="col-md-3">Sony Xperia Z1</div>
                <div class="col-md-3">
                    Sony Xperia C5</div>
                <div class="col-md-3">Sony Xperia XZ1</div>
                <div class="col-md-3">Sony Xperia XA1 Dual</div>
                <div class="col-md-3">Sony Xperia XA1 Ultra</div>
                <div class="col-md-3">Sony Xperia XZ</div>
                <div class="col-md-3">Sony Xperia T3</div>
                <div class="col-md-3">Sony Xperia XA</div>
                <div class="col-md-3">Sony Xperia ZR</div>
                <div class="col-md-3">Sony Xperia Z3+</div>
                <div class="col-md-3">Sony Xperia SP</div>
                <div class="col-md-3">
                    Sony Xperia XA1 Plus</div>
                <div class="col-md-3">Sony Xperia Z2</div>
                <div class="col-md-3">Sony Xperia Z5</div>
                <div class="col-md-3">Sony Xperia T2</div>
                <div class="col-md-3">Sony Xperia E3</div>
                <div class="col-md-3">Sony Xperia Z3</div>
                <div class="col-md-3">Sony Xperia L2</div>
                <div class="col-md-3">Sony Xperia M2</div>
                <div class="col-md-3"></div>


            </div>
                </div>
                <div >
                <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Asus</h5>
                <div class="col-md-3">Asus ROG Phone</div>
            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Vivo</h5>
                <div class="col-md-3">Vivo X70 Pro Plus</div>
                <div class="col-md-3">Vivo X70 Pro</div>
                <div class="col-md-3">Vivo X70</div>
                <div class="col-md-3">Vivo Z6</div>
                <div class="col-md-3">Vivo S10 Pro</div>
                <div class="col-md-3">Vivo Nex 3S</div>
                <div class="col-md-3">Vivo S9 5G</div>
                <div class="col-md-3">Vivo X60T Pro Plus</div>
                <div class="col-md-3">Vivo X60T</div>
                <div class="col-md-3">Vivo Y53s</div>
            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">HTC</h5>
                <div class="col-md-3">HTC One A9</div>
                <div class="col-md-3">HTC Desire 20 Plus</div>
                <div class="col-md-3">HTC U Ultra</div>
                <div class="col-md-3">
                    HTC 10 Evo</div>
                <div class="col-md-3">HTC U11 Plus</div>
                <div class="col-md-3">HTC Desire 10 Pro</div>
                <div class="col-md-3">HTC One E8</div>
                <div class="col-md-3">HTC Desire Eye</div>
                <div class="col-md-3">HTC 10 Lifestyle</div>
                <div class="col-md-3">HTC One ME</div>
                <div class="col-md-3">HTC One E9+</div>
                <div class="col-md-3">HTC One M9 Plus</div>

            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Honor</h5>
                <div class="col-md-3">Honor 8</div>
                <div class="col-md-3">Honor View 20</div>
                <div class="col-md-3">Honor V10</div>
                <div class="col-md-3">Honor 8 Pro</div>

            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Essential</h5>
                <div class="col-md-3">Essential PH-1</div>

            </div>
                </div>
                <div >
                    <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Oppo</h5>
                <div class="col-md-3">OPPO Reno6</div>
                <div class="col-md-3">
                    OPPO Reno6 Pro</div>
                <div class="col-md-3">OPPO Reno 4</div>
                <div class="col-md-3">OPPO Reno 2</div>
                <div class="col-md-3">OPPO Find X2</div>
                <div class="col-md-3">
                    OPPO R17 Pro</div>
                <div class="col-md-3">
                    OPPO Find X2</div>
                <div class="col-md-3">
                    OPPO R17 Pro</div>
                <div class="col-md-3">OPPO Reno 6 Pro Plus</div>
                <div class="col-md-3">OPPO Reno 3</div>
                <div class="col-md-3">OPPO Find X3 Pro</div>
                <div class="col-md-3">OPPO Reno 6 Pro Plus</div>
                <div class="col-md-3">OPPO K9</div>
                <div class="col-md-3">OPPO A95</div>
                <div class="col-md-3">OPPO Find X3</div>
                <div class="col-md-3">OPPO K9 Pro</div>
                <div class="col-md-3">OPPO Reno 5 Pro</div>
                <div class="col-md-3">OPPO Reno 10x</div>
                <div class="col-md-3">
                    OPPO N1</div>
                <div class="col-md-3">OPPO Reno 5A</div>
                <div class="col-md-3">OPPO A16s</div>
                <div class="col-md-3">OPPO A94</div>
                <div class="col-md-3">OPPO Find X3 Neo</div>
                <div class="col-md-3">OPPO Reno 5 Lite</div>
                <div class="col-md-3">OPPO Reno 3A</div>
                <div class="col-md-3">OPPO N1 Mini</div>


            </div>
                </div>
                <div >
                <div class="row">
                <h5 class="mb-2 mt-4 fw-800">Poco</h5>
                <div class="col-md-3">Poco F2 Pro</div>
                <div class="col-md-3">
                    Poco F3</div>
                <div class="col-md-3">Poco X3 GT</div>
             </div>
            </div>
                </div>
                {/* Add more card sections for other device categories */}
                {/* Example: LG, Vivo, HTC, Honor, Essential, Oppo, Poco, etc. */}
            </div><br/><br/><br/><br/>
        </section>
    );
}

export default Compatible;
