import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import "./Modal.css";
import html2canvas from "html2canvas";
import { Resizable } from "re-resizable";
import Draggable from "react-draggable";
import "./CustomizeCard.css";
import qrCode from "../assets/images/cardQRCode.jpeg";
import mattBlack from "../assets/images/matt_black_card.png";
import glossBlack from "../assets/images/gloss_black_card.png";
import minimalWhite from "../assets/images/minimal_white_card.png";
import goldCard from "../assets/images/Gold.png";
import silverCard from "../assets/images/silver.png";
import ColorCard from "../assets/images/Color.png";
import customizeLogo from "../assets/images/customisation-logo.jpeg";
import { IoCartOutline } from "react-icons/io5";
import { toast } from "react-toastify";

const CustomizeCard = (props) => {
  const [cardSide, setCardSide] = useState("front");
  const [activePage, setActivePage] = useState("text");
  const [printOption, setPrintOption] = useState("silver-lining");

  const [frontSelectorImages, setFrontSelectorImages] = useState([]);

  const [backSelectorImages, setBackSelectorImages] = useState([]);

  const [frontImages, setFrontImages] = useState([]);
  const [backImages, setBackImages] = useState([]);
  const [fontStyleList, setFontStyleList] = useState([
    "EB Garamond",
    "Lato",
    "Libre Caslon Text",
    "Merriweather",
    "Noto Sans Georgian",
    "Open Sans",
    "Roboto",
    "Ubuntu",
  ]);

  const [cardStyle, setCardStyle] = useState("");

  const [cardFrontRef, setCardFrontRef] = useState("");
  const [cardBackRef, setCardBackRef] = useState("");

  const [frontCardElement, setFrontCardElement] = useState("");
  const [backCardElement, setBackCardElement] = useState("");

  const [imageTouched, setImageTouched] = useState({
    touched: false,
    index: 0,
  });

  const [qrCodeStyleData, setQrCodeStyleData] = useState({
    position: { x: 250, y: 75 },
    size: { width: 100, height: 100 },
  });

  useEffect(() => {
    const updateDimensions = () => {
      const cardBackElement = document.getElementById("card-display-back");

      if (cardBackElement) {
        const width = cardBackElement.offsetWidth;
        const height = cardBackElement.offsetHeight;
        if (props.isBasicCard) {
          setQrCodeStyleData({
            size:
              width < 250
                ? { width: 50, height: 50 }
                : { width: 100, height: 100 },
            position:
              width < 250
                ? { x: (width - 50) / 2, y: (height - 50) / 2 }
                : { x: (width - 100) / 2, y: (height - 100) / 2 },
          });
        } else {
          setQrCodeStyleData({
            size:
              width < 250
                ? { width: 50, height: 50 }
                : { width: 100, height: 100 },
            position:
              width < 250
                ? { x: width - 60, y: (height - 50) / 2 }
                : { x: width - 120, y: (height - 100) / 2 },
          });
        }
      }
    };

    updateDimensions();

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, [cardSide]);

  useEffect(() => {
    if (cardFrontRef !== null && cardFrontRef !== undefined)
      setFrontCardElement(cardFrontRef);
    if (cardBackRef !== null && cardBackRef !== undefined)
      setBackCardElement(cardBackRef);
  }, [cardFrontRef, cardBackRef]);

  const initialValue = [
    {
      id: 1,
      color: "#808080",
      fontSize: 16,
      text: "",
      position: { x: 0, y: 0 },
      font: "EB Garamond",
      fontWeight: 500,
    },
    {
      id: 2,
      color: "#808080",
      fontSize: 16,
      text: "",
      position: { x: 0, y: 0 },
      font: "EB Garamond",
      fontWeight: 500,
    },
  ];

  const [frontText, setFrontText] = useState(initialValue);
  const [backText, setBackText] = useState(initialValue);

  useEffect(() => {
    props.designAssistanceChecked === true && props.onHide();
  }, [props]);

  useEffect(() => {
    const cardId = props?.data?.id;
    if (cardId === 4) setCardStyle("matte-black");
    else if (cardId === 5) setCardStyle("white");
    else if (cardId === 6) setCardStyle("glass-black");
  }, [props]);

  const handleDataChange = (index, key, val) => {
    if (cardSide === "front") {
      const data = [...frontText];
      data[index] = { ...data[index], [key]: val };
      setFrontText(data);
    } else if (cardSide === "back") {
      const data = [...backText];
      data[index] = { ...data[index], [key]: val };
      setBackText(data);
    }
  };

  const cardData = () => {
    if (cardSide === "front") return frontText;
    else if (cardSide === "back") return backText;
  };

  const displayImageCard = (images) => {
    const imageArray = [];
    for (let i = 0; i < 4; i++) {
      if (images[i]) imageArray.push(images[i]);
      else imageArray.push(null);
    }
    return imageArray;
  };

  const cardImageData = () => {
    if (cardSide === "front") return displayImageCard(frontSelectorImages);
    else if (cardSide === "back") return displayImageCard(backSelectorImages);
  };

  const addCardText = () => {
    if (cardSide === "front") {
      setFrontText([
        ...frontText,
        { ...initialValue[0], id: frontText.length + 1 },
      ]);
    } else if (cardSide === "back") {
      setBackText([
        ...backText,
        { ...initialValue[0], id: frontText.length + 1 },
      ]);
    }
  };

  const handleDeleteText = (index) => {
    if (cardSide === "front") {
      const data = [...frontText];
      data.splice(index, 1);
      setFrontText(data);
    } else if (cardSide === "back") {
      const data = [...backText];
      data.splice(index, 1);
      setBackText(data);
    }
  };

  const handleDeleteSelectorImage = (index) => {
    if (cardSide === "front") {
      const data = [...frontSelectorImages];
      data.splice(index, 1);
      setFrontSelectorImages(data);
    } else if (cardSide === "back") {
      const data = [...backSelectorImages];
      data.splice(index, 1);
      setBackSelectorImages(data);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = function (e) {
      if (cardSide === "front") {
        setFrontSelectorImages([...frontSelectorImages, e.target.result]);
      } else if (cardSide === "back") {
        setBackSelectorImages([...backSelectorImages, e.target.result]);
      }
    };
    fileReader.readAsDataURL(file);
  };

  const cardBackImage = {
    "matte-black": mattBlack,
    white: minimalWhite,
    "glass-black": glossBlack,
  };

  const handleDownloadCard = async () => {
    async function downloadElement(cardElement, name) {
      const card = await cardElement?.cloneNode(true);
      if (card) {
        const tempCard = document.body.appendChild(card);
        const canvas = await html2canvas(tempCard);
        const cardImageURL = canvas.toDataURL("image/png");
        const a = document.createElement("a");
        a.href = cardImageURL;
        a.download = `${name}.png`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        document.body.removeChild(tempCard);
      }
    }

    function downloadImage(cardImagePath, name) {
      const a = document.createElement("a");
      a.href = cardImagePath;
      a.download = `${name}.png`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }

    await downloadElement(frontCardElement, "cardImage-front");
    backCardElement
      ? await downloadElement(backCardElement, "cardImage-back")
      : downloadImage(cardBackImage[cardStyle], "cardImage-back");
  };

  const addImageToCard = (index) => {
    if (cardSide === "front") {
      setFrontImages([
        ...frontImages,
        {
          image: frontSelectorImages[index],
          position: { x: 0, y: 0 },
          size: { width: 60, height: 40 },
        },
      ]);
    }
    if (cardSide === "back") {
      setBackImages([
        ...backImages,
        {
          image: backSelectorImages[index],
          position: { x: 0, y: 0 },
          size: { width: 60, height: 40 },
        },
      ]);
    }
  };

  const readCard = async (cardElement) => {
    const card = await cardElement?.cloneNode(true);
    if (card) {
      document.body.appendChild(card);
      const canvas = await html2canvas(card);
      const cardImageURL = canvas.toDataURL("image/png");
      document.body.removeChild(card);
      return cardImageURL;
    }
  };

  const readCardImage = async (image = minimalWhite) => {
    const data = await fetch(image);
    const response = await data.arrayBuffer();
    const file = new Blob([response], { type: "image/png" });
    return new Promise((resolve, reject) => {
      let fileReader = new FileReader();
      fileReader.onload = function (e) {
        resolve(e.target.result);
      };
      fileReader.readAsDataURL(file);
    });
  };

  const handleSave = async () => {
    let valid = false;
    const filteredFrontText = frontText.filter((data) => {
      if (data.text) valid = true;
      return data.text;
    });

    const filteredBackText = backText.filter((data) => {
      if (data.text) valid = true;
      return data.text;
    });

    if (frontImages.length > 0 || backImages.length > 0) {
      valid = true;
    }

    const customData = {
      printOption,
      frontTextAndStyle: filteredFrontText,
      backTextAndStyle: filteredBackText,
      frontImage: await readCard(frontCardElement),
      backImage: backCardElement
        ? await readCard(backCardElement)
        : await readCardImage(cardBackImage[cardStyle]),
      frontImageComponents: frontImages,
      backImageComponents: backImages,
    };

    if (!valid) {
      toast.error("Please add some data", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      await props.addToCart({ ...props.data, ...customData }, true);
      props.onHide();
    }
  };

  return (
    <Modal
      {...props}
      dialogClassName="modal-style"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        className="modal-header"
        style={{ padding: "0 20px 0 0" }}
      >
        <img src={customizeLogo} alt="" style={{ marginRight: "20px" }} />
        <Modal.Title id="contained-modal-title-vcenter">
          <div
            style={{
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "1em",
              fontFamily: "Pixelify Sans, sans-serif",
            }}
          >
            {props.isBasicCard ? "Basic Cards" : "Custom Cards"}
          </div>
          <div style={{ fontSize: "12px", fontWeight: 500 }}>Customization</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ padding: 0 }}
        onClick={(e) => {
          setImageTouched({ touched: false, index: 0 });
        }}
      >
        <div style={{ padding: "0" }}>
          <div>
            <div className="modal-grid">
              <div className="column-1">
                <div
                  className={`card-select ${
                    cardSide === "front" && "selected"
                  }`}
                  onClick={() => setCardSide("front")}
                >
                  <div className="title">Front Side</div>
                  <div
                    className={` card-side`}
                    style={{ backgroundColor: props?.data?.cardColor }}
                  ></div>
                </div>

                <div
                  className={`card-select ${cardSide === "back" && "selected"}`}
                  onClick={() => setCardSide("back")}
                >
                  <div className="title">Back Side</div>
                  <div
                    className={`card-side`}
                    style={{ backgroundColor: props?.data?.cardColor }}
                  ></div>
                </div>
              </div>
              {cardSide === "front" ? (
                <div className="card-display-grid" key="front">
                  <div className="card-outline">
                    <div
                      ref={(ref) => setCardFrontRef(ref)}
                      className={`card-display`}
                      style={{ backgroundColor: props?.data?.cardColor }}
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={(e) => {
                        const dropImageIndex =
                          e.dataTransfer.getData("imageIndex");
                        setFrontImages([
                          ...frontImages,
                          {
                            image: frontSelectorImages[dropImageIndex],
                            position: { x: 0, y: 0 },
                            size: { width: 60, height: 40 },
                          },
                        ]);
                      }}
                    >
                      {frontImages?.length > 0 &&
                        frontImages?.map((frontImage, index) => (
                          <Draggable
                            bounds="parent"
                            key={index}
                            onStop={(e, dragElement) => {
                              const data = [...frontImages];
                              data[index].position = {
                                x: dragElement.x,
                                y: dragElement.y,
                              };
                              setFrontImages(data);
                            }}
                            position={frontImage?.position}
                          >
                            <Resizable
                              size={frontImage.size}
                              onResizeStop={(e, direction, ref, delta) => {
                                const data = [...frontImages];
                                data[index].size = {
                                  width: ref.style.width,
                                  height: ref.style.height,
                                };
                                setFrontImages(data);
                              }}
                              style={
                                imageTouched?.index === index &&
                                imageTouched?.touched === true
                                  ? {
                                      cursor: "pointer",
                                      position: "absolute",
                                      border: "2px solid blue",
                                    }
                                  : {
                                      cursor: "pointer",
                                      position: "absolute",
                                    }
                              }
                              className={"draggable-image"}
                              onTouchStart={(e) => {
                                setImageTouched({
                                  touched: true,
                                  index,
                                });
                              }}
                            >
                              <img
                                src={frontImage?.image}
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                              />
                              <i
                                className="fas fa-trash delete-icon"
                                onClick={() =>
                                  setFrontImages([
                                    ...frontImages.filter(
                                      (frontImage, imgIndex) =>
                                        imgIndex !== index
                                    ),
                                  ])
                                }
                                onTouchStart={() =>
                                  setFrontImages([
                                    ...frontImages.filter(
                                      (frontImage, imgIndex) =>
                                        imgIndex !== index
                                    ),
                                  ])
                                }
                                style={
                                  imageTouched?.index === index &&
                                  imageTouched?.touched === true
                                    ? {
                                        color: "red",
                                        visibility: "visible",
                                      }
                                    : {
                                        color: "red",
                                      }
                                }
                              ></i>
                            </Resizable>
                          </Draggable>
                        ))}
                      {frontText?.map((textData, index) => (
                        <Draggable
                          bounds="parent"
                          key={index}
                          onStop={(e, dragElement) => {
                            const data = [...frontText];
                            data[index].position = {
                              x: dragElement.x,
                              y: dragElement.y,
                            };
                            setFrontText(data);
                          }}
                          position={textData?.position}
                        >
                          <p
                            style={{
                              width: "fit-content",
                              margin: 0,
                              fontFamily: `${textData?.font}, sans-serif`,
                              color: textData.color,
                              fontSize: `${textData?.fontSize}px`,
                              cursor: "pointer",
                              position: "absolute",
                              fontWeight: textData?.fontWeight,
                            }}
                          >
                            {textData.text}
                          </p>
                        </Draggable>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card-display-grid" key="back">
                  <div className="card-outline">
                    <div
                      ref={(ref) => {
                        setCardBackRef(ref);
                      }}
                      id="card-display-back"
                      className={`card-display`}
                      style={{ backgroundColor: props?.data?.cardColor }}
                      onDragStart={(e) => {
                        e.preventDefault();
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={(e) => {
                        const dropImageIndex =
                          e.dataTransfer.getData("imageIndex");
                        setBackImages([
                          ...backImages,
                          {
                            image: backSelectorImages[dropImageIndex],
                            position: { x: 0, y: 0 },
                            size: { width: 60, height: 40 },
                          },
                        ]);
                      }}
                    >
                      {backImages?.length > 0 &&
                        backImages?.map((backImage, index) => (
                          <Draggable
                            bounds="parent"
                            key={index}
                            onStop={(e, dragElement) => {
                              const data = [...backImages];
                              data[index].position = {
                                x: dragElement.x,
                                y: dragElement.y,
                              };
                              setBackImages(data);
                            }}
                            position={backImage?.position}
                          >
                            <Resizable
                              size={backImage.size}
                              onResizeStop={(e, direction, ref, delta) => {
                                const data = [...backImages];
                                data[index].size = {
                                  width: ref.style.width,
                                  height: ref.style.height,
                                };
                                setBackImages(data);
                              }}
                              style={
                                imageTouched?.index === index &&
                                imageTouched?.touched === true
                                  ? {
                                      cursor: "pointer",
                                      position: "absolute",
                                      border: "2px solid blue",
                                    }
                                  : {
                                      cursor: "pointer",
                                      position: "absolute",
                                    }
                              }
                              className={"draggable-image"}
                              onTouchStart={(e) => {
                                setImageTouched({
                                  touched: true,
                                  index,
                                });
                              }}
                            >
                              <img
                                src={backImage?.image}
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                              />
                              <i
                                className="fas fa-trash delete-icon"
                                style={
                                  imageTouched?.index === index &&
                                  imageTouched?.touched === true
                                    ? {
                                        color: "red",
                                        visibility: "visible",
                                      }
                                    : {
                                        color: "red",
                                      }
                                }
                                onClick={() =>
                                  setBackImages([
                                    ...backImages.filter(
                                      (backImage, imgIndex) =>
                                        imgIndex !== index
                                    ),
                                  ])
                                }
                                onTouchStart={() =>
                                  setBackImages([
                                    ...backImages.filter(
                                      (backImage, imgIndex) =>
                                        imgIndex !== index
                                    ),
                                  ])
                                }
                              ></i>
                            </Resizable>
                          </Draggable>
                        ))}
                      {backText?.map((textData, index) => (
                        <Draggable
                          bounds="parent"
                          key={index}
                          onStop={(e, dragElement) => {
                            const data = [...backText];
                            data[index].position = {
                              x: dragElement.x,
                              y: dragElement.y,
                            };
                            setBackText(data);
                          }}
                          position={textData?.position}
                        >
                          <p
                            style={{
                              width: "fit-content",
                              margin: 0,
                              color: textData.color,
                              fontSize: `${textData.fontSize}px`,
                              cursor: "pointer",
                              position: "absolute",
                              fontWeight: textData?.fontWeight,
                              fontFamily: `${textData?.font}, sans-serif`,
                            }}
                          >
                            {textData.text}
                          </p>
                        </Draggable>
                      ))}
                      <Draggable
                        bounds="parent"
                        onStop={(e, dragElement) => {
                          const data = { ...qrCodeStyleData };
                          data.position = {
                            x: dragElement.x,
                            y: dragElement.y,
                          };
                          setQrCodeStyleData(data);
                        }}
                        position={qrCodeStyleData?.position}
                      >
                        <Resizable
                          size={qrCodeStyleData.size}
                          onResizeStop={(e, direction, ref, delta) => {
                            const data = { ...qrCodeStyleData };
                            data.size = {
                              width: ref.style.width,
                              height: ref.style.height,
                            };
                            setQrCodeStyleData(data);
                          }}
                          style={{
                            backgroundImage: `url(${qrCode})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            cursor: "pointer",
                            borderRadius: "6px",
                            position: "absolute",
                          }}
                          lockAspectRatio={true}
                          className={"draggable-image"}
                        ></Resizable>
                      </Draggable>
                    </div>
                  </div>
                </div>
              )}

              <div className="column-3">
                <div className="navigator">
                  <div
                    onClick={() => setActivePage("text")}
                    className={`nav-item ${activePage === "text" && "active"}`}
                  >
                    Text
                  </div>
                  <div
                    onClick={() => setActivePage("print")}
                    className={`nav-item ${activePage === "print" && "active"}`}
                  >
                    Print option
                  </div>
                  <div
                    onClick={() => setActivePage("upload")}
                    className={`nav-item ${
                      activePage === "upload" && "active"
                    }`}
                  >
                    Uploads
                  </div>
                </div>
                {activePage === "text" && (
                  <div className="text">
                    {cardData()?.map((textData, index) => (
                      <div className="text-input" key={index}>
                        <div className="input-container">
                          <label htmlFor="text-data">
                            {(index === 0 && "Primary Text Field") ||
                              (index === 1 && "Secondary Text Field") || (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  Additional Text Field
                                  <i
                                    className="fas fa-trash"
                                    onClick={() => handleDeleteText(index)}
                                    style={{ color: "red", cursor: "pointer" }}
                                  ></i>
                                </div>
                              )}
                          </label>
                          <input
                            type="text"
                            id="text-data"
                            value={textData.text}
                            placeholder="Ex. Jhon Doe"
                            onChange={(e) =>
                              handleDataChange(index, "text", e.target.value)
                            }
                          />
                        </div>
                        <div className="input-grid">
                          <div className="input-container">
                            <label htmlFor="font">Font</label>
                            <select
                              name="font"
                              id="font"
                              value={textData.font}
                              onChange={(e) => {
                                handleDataChange(index, "font", e.target.value);
                              }}
                            >
                              {fontStyleList?.map((style, index) => (
                                <option
                                  style={{
                                    fontFamily: `${style}, sans-serif`,
                                  }}
                                  key={index}
                                  value={style}
                                >
                                  {style}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="input-container">
                            <label htmlFor="font-weight">Font Weight</label>
                            <select
                              name="font-weight"
                              id="font-weight"
                              onChange={(e) =>
                                handleDataChange(
                                  index,
                                  "fontWeight",
                                  parseInt(e.target.value)
                                )
                              }
                            >
                              <option style={{ fontWeight: 100 }} value="100">
                                Light
                              </option>
                              <option
                                default
                                style={{ fontWeight: 400 }}
                                value="400"
                              >
                                Normal
                              </option>
                              <option style={{ fontWeight: 700 }} value="700">
                                Bold
                              </option>
                            </select>
                          </div>
                          <div className="input-container">
                            <label htmlFor="font-size">Font Size</label>
                            <input
                              type="number"
                              id="font-size"
                              value={textData.fontSize}
                              onChange={(e) =>
                                handleDataChange(
                                  index,
                                  "fontSize",
                                  e.target.value
                                )
                              }
                            />
                          </div>
                          <div className="input-container">
                            <label htmlFor="text-color">Text Color</label>
                            <input
                              type="color"
                              id="text-color"
                              value={textData.color}
                              className="color-picker"
                              onChange={(e) =>
                                handleDataChange(index, "color", e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="add-text" onClick={addCardText}>
                      <div style={{ fontWeight: 900, fontSize: "14px" }}>+</div>
                      <div>Add a new Text field</div>
                    </div>
                  </div>
                )}
                {activePage === "print" && (
                  <div className="print-option">
                    <div>
                      <div
                        className="print-option-button"
                        onClick={() => setPrintOption("silver-lining")}
                      >
                        <input
                          type="radio"
                          id="silver-lining"
                          checked={printOption === "silver-lining"}
                        />
                        <label for="silver-lining">Silver lining</label>
                      </div>

                      <div
                        className="print-option-button"
                        onClick={() => setPrintOption("gold-lining")}
                      >
                        <input
                          type="radio"
                          id="gold-lining"
                          checked={printOption === "gold-lining"}
                        />
                        <label for="gold-lining">Gold lining</label>
                      </div>

                      <div
  className="print-option-button"
  onClick={() => {
    if (props.isBasicCard) {
      // Display a toast message indicating the option is not available
      toast.info("The Color option is not available for basic cards.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      // If not a basic card, set the print option normally
      setPrintOption("color");
    }
  }}
  style={
    props.isBasicCard
      ? {
          backgroundColor: "#888",
          cursor: "pointer", // Ensure cursor indicates the button is not interactive
          pointerEvents: "all", // Keep pointer events to capture clicks for the toast
        }
      : { backgroundColor: "#000", cursor: "pointer", pointerEvents: "all" }
  }
>
  <input
    type="radio"
    id="color"
    checked={printOption === "color"}
    disabled={props.isBasicCard} // Keep this to ensure form consistency, though it's visually disabled
  />
  <label htmlFor="color">Color</label>
</div>

                    </div>
                    <div>
                      {printOption === "gold-lining" && (
                        <div style={{ padding: "10px" }}>
                          <img
                            src={goldCard}
                            alt="gold-card"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      )}

                      {printOption === "silver-lining" && (
                        <div style={{ padding: "10px" }}>
                          <img
                            src={silverCard}
                            alt="silver-card"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      )}
                      {printOption === "color" && (
                        <div style={{ padding: "10px" }}>
                          <img
                            src={ColorCard}
                            alt="color-card"
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activePage === "upload" && (
                  <div className="upload-image">
                    <div className="display-images">
                      {cardImageData()?.map((image, index) =>
                        image ? (
                          <div className="img-container" key={index}>
                            <img
                              src={image}
                              alt=""
                              style={{
                                width: "100%",
                                borderRadius: "15px",
                                height: "100%",
                              }}
                              draggable={true}
                              onDragStart={(e) =>
                                e.dataTransfer.setData("imageIndex", index)
                              }
                              onDoubleClick={() => addImageToCard(index)}
                            />
                            <i
                              className="fas fa-trash delete-icon"
                              style={{ color: "red" }}
                              onClick={() => handleDeleteSelectorImage(index)}
                            ></i>
                          </div>
                        ) : (
                          <div
                          className="img-container"
                          key={index}
                          onClick={() => {
                            if (props.isBasicCard) {
                              toast.info(" Uploading the Images for Basic Cards are Disabled.", {
                                position: "top-right",
                                autoClose: 9000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }
                          }}
                          style={
                            props.isBasicCard
                              ? {
                                  backgroundColor: "#ddd",
                                  color: "#ddd",
                                  cursor: "not-allowed",
                                }
                              : {
                                  backgroundColor: "#fff",
                                  color: "#000",
                                  cursor: "pointer",
                                }
                          }
                        >
                          <label
                            style={
                              props.isBasicCard
                                ? {
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "24px",
                                    cursor: "pointer",
                                  }
                                : {
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "24px",
                                    cursor: "pointer",
                                  }
                            }
                          >
                            +
                            <input
                              type="file"
                              onChange={handleImageUpload}
                              style={{ display: "none" }}
                              disabled={props.isBasicCard}
                            />
                          </label>
                        </div>
                        
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <input
            type="checkbox"
            id="designAssistanceCheckbox"
            checked={props.designAssistanceChecked}
            onChange={props.handleDesignAssistanceCheckbox}
            // className="checkbox-small"
            disabled={props.isBasicCard}
          />
          <label
            htmlFor="designAssistanceCheckbox"
            className="checkbox-label"
            style={props.isBasicCard ? { color: "#aaa" } : { color: "#000" }}
          >
            Need any design assistance
          </label>
        </div>
        <div>
          <button className="button-outline" onClick={handleDownloadCard}>
            Save in Design
          </button>
          <button className="button-primary" onClick={handleSave}>
            <IoCartOutline /> Add to Cart
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomizeCard;
