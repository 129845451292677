import React, { forwardRef, useState } from "react";
import "./Productlist.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useLocationContext } from "./LocationInterface";

const Basiccards = forwardRef((props, ref) => {
  const [hoveredImageIndex, setHoveredImageIndex] = useState(-1);

  const { currencySymbol, priceType } = useLocationContext();

  const handleMouseEnter = (index) => {
    setHoveredImageIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredImageIndex(-1);
  };

  const handleAddToCart = (product) => {
    // Implement your logic for adding the product to cart
  };

  return (
    <div>
      <img
        src={require("../assets/images/hero banner 1.png")}
        alt="Liwqnk"
        className="product-list-image"
        style={{ objectFit: "cover", width: "100%", height: "auto" }}
        loading="lazy"
      />
      <Container
        ref={ref}
        className="cardyt"
        style={{ marginTop: "60px", scrollMargin: "100px" }}
      >
        <h3 ref={ref}>Basic Cards</h3>
        <div className="product-list">
          {props?.basicCards?.length > 0 ? (
            props?.basicCards?.map((product, index) => (
              <Link
                to={`/product-detail/${product.slug}`}
                style={{
                  justifySelf: "center",
                  textDecoration: "none",
                  color: "inherit",
                }}
                key={product.id}
              >
                <div
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={{
                    border: "1px solid #ccc",
                    padding: "10px",
                    maxWidth: "290px",
                    alignSelf: "flex-start",
                    borderRadius: "8px",
                    width: "100%",
                    height: "100%",
                  }}
                  className="cardy"
                >
                  {((index % 2 === 0 && hoveredImageIndex !== index) ||
                    (index % 2 !== 0 && hoveredImageIndex === index)) &&
                  product.images &&
                  product.images.length >= 4 ? (
                    <img
                      src={`https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${product.images[3]}`}
                      alt={product.name}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        aspectRatio: "1/1",
                      }}
                    />
                  ) : (
                    product.image && (
                      <img
                        src={`https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${product.image}`}
                        alt={product.name}
                        style={{
                          width: "100%",
                          borderRadius: "10px",
                          aspectRatio: "1/1",
                        }}
                      />
                    )
                  )}
                  <p
                    style={{
                      fontWeight: "bold",
                      marginTop: "14px",
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      fontSize: "14px",
                      textWrap: "wrap",
                    }}
                  >
                    {product.name}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{ margin: 0, color: "black", fontWeight: "bold" }}
                    >
                      Price: {currencySymbol} {product[priceType]}
                    </p>

                    <button
                      onClick={() => handleAddToCart(product)}
                      style={{
                        background:
                          "linear-gradient(90deg, #04459D 1.87%, #449BFA 95.1%, #348FF2 100%)",
                        borderRadius: "30px",
                        color: "white",
                        padding: "8px 35px",
                        border: "none",
                        cursor: "pointer",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <p>No products available</p>
          )}
        </div>
      </Container>
    </div>
  );
});

export default Basiccards;
