import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconContext } from "react-icons";
import {
  FaLink,
  FaFacebookF,
  FaWhatsapp,
  FaTwitter,
  FaLinkedinIn,
  FaPinterestP,
  FaCalendarDay,
  FaFacebookMessenger,
} from "react-icons/fa";
import { SocialLink } from "./SocialLink.js";
import "./Blogs.css";
import { Helmet } from 'react-helmet';

const BlogDetails = () => {
  const { id } = useParams();
  const [blogData, setBlogData] = useState({});
  const currentLocation = window.location.href;
  const AWS_S3_BUCKET_URL=process.env.REACT_APP_AWS_S3_BUCKET_URL;
  console.log(blogData);
  useEffect(() => {
    const fetchBlog = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/v1/api/public-api/get-blog-details/${id}`
      );
      setBlogData(response.data);
      return;
    };
    fetchBlog();
  }, [id]);
  function capitalizeFirstLetter(string) {
    if (!string) {
      return ""; // Handle cases where the string is empty or undefined
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const getDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  return (
    <div className="container" style={{ marginTop: "100px" }}>
       <Helmet>
       <title>{blogData?.blogs?.title}</title>
       <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
       <meta name="robots" content="index,follow"></meta>
       <meta name="facebook-domain-verification" content="76mxtqr8maf4jd7publr59pn5stjur"></meta>
       <meta name="google-site-verification" content="VS9tF3UvpS-8c0O9z1jclypU0SBa_rFQKlzDZ5TmEKM"></meta>
       <meta name="description" content="Unlock the potential of business cards with NFC for seamless networking. Experience next-level connections with advanced technology."></meta>
       <meta name="title" content="Networking &amp; Event Marketing Blog. Expert Tips &amp; Strategies!"></meta>
       <meta name="description" content="Stay ahead in networking and event marketing with our expert tips &amp; strategies. Boost your skills and level up your success!"></meta>
       <meta name="keywords" content={blogData?.blogs?.title}></meta>
       </Helmet>
      <h1>{blogData?.blogs?.title}</h1>
      <div className="category-tag">
        <span style={{ color: "red" }}>
          {capitalizeFirstLetter(blogData?.blogs?.Category?.name)}
        </span>{" "}
        <span style={{ color: "green" }}>
          {capitalizeFirstLetter(blogData?.blogs?.Subcategory?.name)}
        </span>
      </div>

      <div className="date">
        <FaCalendarDay style={{ marginLeft: 0 }} />
        <div style={{ lineHeight: "2em" }}>
          &nbsp;{getDate(blogData?.blogs?.createdAt)}
        </div>
      </div>
      <img
        alt=""
        className="img-fluid detail-image"
        src={`https://${AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${blogData?.blogs?.image}`}
        width={"20%"}
      ></img>
      <div className="icons">
        <IconContext.Provider value={{ className: "react-icons" }}>
          <button
            className="icon-btn"
            style={{ backgroundColor: "#607D8B" }}
            onClick={() => navigator.clipboard.writeText(currentLocation)}
          >
            <FaLink />
          </button>
          <SocialLink
            color="#4267B2"
            targetLink={`https://www.facebook.com/sharer/sharer.php?u=${currentLocation}`}
          >
            <FaFacebookF />
          </SocialLink>
          <SocialLink
            color="#25D366"
            targetLink={`https://web.whatsapp.com/send?text=${currentLocation}`}
            dataAction="share/whatsapp/share"
          >
            <FaWhatsapp />
          </SocialLink>
          <SocialLink
            color="#00ACEE"
            targetLink={`https://twitter.com/intent/tweet?text=${currentLocation}`}
          >
            <FaTwitter />
          </SocialLink>
          <SocialLink
            color="#006FA6"
            targetLink={`https://www.linkedin.com/sharing/share-offsite/?url=${currentLocation}`}
          >
            <FaLinkedinIn />
          </SocialLink>
          <SocialLink
            color="#BD091D"
            targetLink={`https://www.pinterest.com/pin/create/button/?url=${currentLocation}`}
          >
            <FaPinterestP />
          </SocialLink>
          <SocialLink
            color="#0080FF"
            // targetLink={`https://www.facebook.com/dialog/share?link=${"connct.ai/blog/QR-code-visiting-card-connectivity"}`}
          >
            <FaFacebookMessenger />
          </SocialLink>
        </IconContext.Provider>
      </div>
      <div
        className="blog-description"
        dangerouslySetInnerHTML={{ __html: blogData?.blogs?.description }}
      ></div>
    </div>
  );
};

export default BlogDetails;
