import Dexie from "dexie";

// Define the database name
const dbName = `${process.env.REACT_APP_BASE_URL}/connctDB`;

// Create a function to check and create the indexedDB
async function createIndexedDB() {
  // Check if the database already exists
  const dbExists = await Dexie.exists(dbName);

  // If the database doesn't exist, create it
  if (!dbExists) {
    const connctDB = new Dexie(dbName);

    // Define the schema and version
    connctDB.version(10).stores({
      cart: "id, cid, name, price, quantity, printOption, frontFontFamily, backFontFamily, frontTextAndStyle, backTextAndStyle, frontImage, backImage, frontImageComponents, backImageComponents",
    });

    // Open the database to trigger the creation
    await connctDB.open();
  }
}

// Call the function to check and create the indexedDB
await createIndexedDB();

// Export the database instance
export const connctDB = new Dexie(dbName);
connctDB.version(10).stores({
  cart: "id, cid, name, price, quantity, printOption, printOption,frontFontFamily,backFontFamily,frontTextAndStyle,backTextAndStyle,frontImage,backImage,frontImageComponents,backImageComponents",
});
