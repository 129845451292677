import React from 'react'
import './Enterprise.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';

const Enterprise = () => {
  return (
    <div>
         <Helmet>
         <title>Digital Business Card for Enterprise | Connct</title>
         <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
         <meta name="robots" content="index,follow"></meta>
         <meta name="facebook-domain-verification" content="76mxtqr8maf4jd7publr59pn5stjur"></meta>
         <meta name="google-site-verification" content="VS9tF3UvpS-8c0O9z1jclypU0SBa_rFQKlzDZ5TmEKM"></meta>
         <meta name="description" content="Get your favorite from our collection of digital business cards. Classic matt black, minimalistic white, gloss black, choose the one that interests you."></meta>
         <meta name="title" content="Digital Business Cards Collection | Connct"></meta>
         <meta property="og:title" content="Digital Business Cards Collection | Connct"></meta>
         <meta property="og:site_name" content="Connct.ai"></meta>
         <meta property="og:url" content="https://connct.ai/product-list"></meta>
         <meta property="og:description" content="Get your favorite from our collection of digital business cards. Classic matt black, minimalistic white, gloss black, choose the one that interests you."></meta>
         <meta property="og:type" content="website"></meta>
         <meta property="og:image" content="https://api.connct.ai/static/images/Connct-_Metallic_Card.png"></meta>
         <meta name="title" content="Unlock Pro Features with your Connct NFC Smart Business cards"></meta>
         <meta name="description" content="Customize Icons, Advanced Analytics, Multiple profiles, and multimedia support. Personalize and connect multiple social profiles"></meta>
         <meta name="title" content="Digital Business Card for Enterprise | Connct"></meta>
         <meta name="description" content="Unlock limitless possibilities, streamline contacts, and elevate your brand with Connct.ai's Enterprise-Grade Smart NFC Business Cards."></meta>
         
         </Helmet>
         <div className="wrapper" style={{marginTop:'150px'}}>
      <section className="enterprise section" >
        <div className="container">
          <div className="page-header">
            {/* Breadcrumb navigation commented out */}
            <h2 style={{fontWeight:'800'}}>Track your team's networking performance</h2>
            <p style={{fontSize:'18px'}}>Equip your team with the next-gen NFC cards and track all their networking performance in one go.</p>
          </div><br/>
          <hr className="lightgray-line" /> <br/>
          <div className="row">
            <div className="col-12">
              <p style={{fontSize:'18px'}}>Drop us an email to equip your team with Conncts' customized one-tap digital business cards in your brand's
                style & improve your team’s networking ROI.</p><br/><br/>
            </div>
            
            <div className="row section" style={{marginTop:'15px'}}>
            <div className="col-md-6">
    <div className="mb-4 d-flex align-items-center">
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#FAAD3D', marginRight: '10px', marginTop: '-17px' }} /> {/* Adjusted margin-bottom */}
        <div>
            <p style={{ fontSize: '18px', fontWeight: '600', margin: '0', padding: '0' }}>Download all your leads</p>
            <p style={{ margin: '0', color: 'black', fontSize: '16px' }}>Download all your contacts directly from your connection list.</p>
        </div>
    </div>
    <div className="mb-4 d-flex align-items-center">
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#FAAD3D', marginRight: '10px', marginTop: '-17px' }} /> {/* Adjusted margin-bottom */}
        <div>
            <p style={{ fontSize: '18px', fontWeight: '600', margin: '0', padding: '0' }}>Eliminate paper wastage</p>
            <p style={{ margin: '0', color: 'black', fontSize: '16px' }}>Every time one team member switches to connect, you take one step towards saving Trees.</p>
        </div>
    </div>
    <div className="mb-4 d-flex align-items-center">
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#FAAD3D', marginRight: '10px', marginTop: '-17px' }} /> {/* Adjusted margin-bottom */}
        <div>
            <p style={{ fontSize: '18px', fontWeight: '600', margin: '0', padding: '0' }}>Track networking efforts</p>
            <p style={{ margin: '0', color: 'black', fontSize: '16px' }}>Track your whole team’s networking performance, calculating overall ROI.</p>
        </div>
    </div>
    <br />
    <h5 className="mb-4" style={{ fontSize: '20px', fontWeight: '600' }}>Set your team with the most-advanced digital visiting card and see the networking ROI jump
        in no time.</h5>
    <br />
    <a href="/contact" className="df" type="button">Get Started</a>
</div>


              <div className="col-md-6">
                <img className="img-fluid mx-auto d-block image-md"
                 src={require('../assets/images/improve-networking-ROI.png')} 
                 alt="Set your team with the most-advanced digital visiting card" 
                 style={{width:'500px',height:'490px'}}
                 />
              </div>
            </div>
                      
            <section className="info-text" style={{marginTop:'40px'}}>
              <div className="container">
                <div className="col-sm-8 mx-auto">
                  <h3 className="fw-bold">Real-time <span style={{color:'rgba(4, 69, 157, 1)'}}>Advanced Analytics</span>. Brand-styled <span
                    style={{color:'#FAAD3D'}}>visiting card</span> design. Standardized <span  style={{color:'#FAAD3D'}}>employee
                    profiles</span></h3>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section><br/><br/><br/>
    </div>
    </div>
  )
}

export default Enterprise