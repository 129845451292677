import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

const TawkChat = () => {
  return (
    <div>
      <TawkMessengerReact
        propertyId="627379ad7b967b11798dde13"
        widgetId="1g29h6ji8"
      />
    </div>
  );
};

export default TawkChat;
