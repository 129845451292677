// Pro.jsx
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./Pro.css";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocationContext } from "./LocationInterface";

const Pro = () => {
  const { currencySymbol, isIndia } = useLocationContext();

  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Unlock Pro Features with your Connct NFC Smart Business cards
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="facebook-domain-verification"
          content="76mxtqr8maf4jd7publr59pn5stjur"
        ></meta>
        <meta
          name="title"
          content="Digital Business Cards Collection | Connct"
        ></meta>
        <meta
          name="google-site-verification"
          content="VS9tF3UvpS-8c0O9z1jclypU0SBa_rFQKlzDZ5TmEKM"
        ></meta>
        <meta
          property="og:title"
          content="Digital Business Cards Collection | Connct"
        ></meta>
        <meta property="og:site_name" content="Connct.ai"></meta>
        <meta property="og:url" content="https://connct.ai/product-list"></meta>
        <meta
          property="og:description"
          content="Get your favorite from our collection of digital business cards. Classic matt black, minimalistic white, gloss black, choose the one that interests you."
        ></meta>
        <meta
          name="description"
          content="Upgrade your networking with Connct.ai's Digital NFC Business Cards. Stay connected effortlessly and make lasting impressions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://api.connct.ai/static/images/Connct-_Metallic_Card.png"
        ></meta>
        <meta
          name="description"
          content="Customize Icons, Advanced Analytics, Multiple profiles, and multimedia support. Personalize and connect multiple social profiles"
        ></meta>
      </Helmet>

      <div class="wrapper">
        <div class="container section" style={{ marginTop: "140px" }}>
          <div className="page-header">
            <nav aria-label="breadcrumb">
              <h2>
                Unveil <b>THE ULTIMATE BUSINESS CARD</b> with Connct PRO
              </h2>
            </nav>
            <br />
            <br />
            <h5 style={{ fontSize: "34px" }}>
              Switch to <b> Business Profile </b>to unlock advanced features
            </h5>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="card-container">
          {/* <div className="card-wrapper" data-aos="fade-up"> */}
          <div
            className="card"
            style={{
              // marginLeft: "200px",
              background:
                "linear-gradient(131deg, #046BD2 1.87%, #6EB4FF 73.23%, rgba(245, 250, 255, 0.73) 99.24%, rgba(245, 250, 255, 0.73) 99.25%)",
              border: "1px solid #046BD2",
              borderRadius: "10px",
              padding: "20px",
              boxSizing: "border-box",
              height: "627px",
            }}
          >
            <div>
              <h2
                className="c-primary"
                style={{ color: "#F5DB7D", fontWeight: "bold" }}
              >
                Standard <br />
                Plan
              </h2>
              <ul className="feature-list" style={{ color: "white" }}>
                <li></li>
                <li></li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Add Contact Details
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Social Media Links
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>One-click contact
                  button
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Contact form
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Add any Link
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Advanced Analytics
                </li>
              </ul>
            </div>

            <Link to="/download-connct" className="choose-plan">
              <div
                style={{
                  display: "inline-block",
                  // marginLeft: "10px",
                  // marginTop: "45px",
                }}
              >
                <button
                  className="choose-plan-button"
                  style={{ fontWeight: "bold" }}
                >
                  Choose Plan
                </button>
              </div>
            </Link>
          </div>
          {/* </div> */}
          {/* <div className="card-wrapper" data-aos="fade-up" data-aos-delay="100"> */}
          <div
            className="card"
            style={{
              background:
                "linear-gradient(43deg, #D0E7FF 4.34%, rgba(235, 235, 235, 0.86) 46.88%, rgba(255, 255, 255, 0.00) 100%)",
              borderColor: "skyblue",
              borderWidth: "2px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "15px",
                backgroundColor: "#F46363",
                color: "#F5DB7D",
                padding: "3px 10px",
                borderRadius: "15px",
                fontSize: "12px",
              }}
            >
              Most Popular
            </div>
            <div>
              <h2 className="c-primary">
                <span
                  style={{
                    fontSize: "28px",
                    fontWeight: "bold",
                    color: "#4996B8",
                  }}
                >
                  Pro
                </span>{" "}
                <br />
                <b style={{ fontSize: "32px", color: "#4996B8" }}>
                  <span
                    style={{
                      fontSize: "42px",
                      fontWeight: "bold",
                      verticalAlign: "middle",
                    }}
                  >
                    {currencySymbol}
                  </span>
                  <span style={{ verticalAlign: "middle" }}>
                    {isIndia ? "999" : "11.97"}
                  </span>
                </b>
                <span
                  style={{
                    fontSize: "16px",
                    color: "#4996B8",
                    fontWeight: "500",
                  }}
                >
                  /Year
                </span>
              </h2>
              <ul className="feature-list">
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Add Contact Details
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Social Media Links
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>One-click contact
                  button
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Contact form
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Other links
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Custom Icons & Title
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Advanced Analytics
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Multiple Profiles
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Photos, Videos & Pdfs
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Personalized Icons
                </li>
                <li style={{ fontSize: "17px" }}>
                  <i className="fas fa-check-circle"></i>Add Custom Links
                </li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <Link to="/download-connct" className="choose-plan">
              <button className="choose-plan-button2">Choose Plan</button>
            </Link>
          </div>
          {/* </div> */}
        </div>
      </div>
      <br />
      <div class="container section">
        <section className="upgrade-plan">
          <div className="row section">
            <div className="col-md-6 align-center" data-aos="fade-up">
              <div className="text-left">
                <div className="col-12" data-aos="fade-up">
                  <h3 className="fw-bold mb-3 c-primary">Advanced Analytics</h3>
                </div>
                <h4 className="fw-normal">
                  Track all the activity your potential customer performs with
                  Connct’s advanced analytics.{" "}
                </h4>
                <p style={{ fontSize: "17px" }}>
                  You can measure ROI by calculating
                </p>
                <ul className="circle-bullet no-bullets">
                  <li data-aos="fade-up">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        paddingRight: "10px",
                        color: "#FAAD3D",
                        fontSize: "20px",
                      }}
                    />
                    The number of profile visits
                  </li>
                  <li data-aos="fade-up">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        paddingRight: "10px",
                        color: "#FAAD3D",
                        fontSize: "20px",
                      }}
                    />
                    Total link taps
                  </li>
                  <li data-aos="fade-up">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        paddingRight: "10px",
                        color: "#FAAD3D",
                        fontSize: "20px",
                      }}
                    />
                    Number of connections
                  </li>
                  <li data-aos="fade-up">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        paddingRight: "10px",
                        color: "#FAAD3D",
                        fontSize: "20px",
                      }}
                    />
                    Total contacts saved
                  </li>
                  <li data-aos="fade-up">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{
                        paddingRight: "10px",
                        color: "#FAAD3D",
                        fontSize: "20px",
                      }}
                    />
                    Link Engagement
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 text-center" data-aos="fade-up">
              <img
                className="img-fluid image-lg mx-auto d-block"
                src={require("../assets/images/analytics.png")}
                alt="Advanced Analytics"
              />
            </div>
          </div>

          <div className="row section">
            <div className="col-12">
              <h3 className="fw-bold mt-4" style={{ fontWeight: "800" }}>
                Unlimited Themes
              </h3>
              <p
                className="small"
                data-aos="fade-up"
                style={{ fontSize: "18px" }}
              >
                Choose between an array of attractive themes to stand out in the
                crowd.
              </p>
            </div>
            <div
              className="col-md-12 my-1"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <img
                className="img-fluid mx-auto d-block image-lg"
                src={require("../assets/images/themes.png")}
                alt="Unlimited Themes"
                style={{ width: "40%", height: "auto" }}
              />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pro;
