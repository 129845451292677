import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import "./Productdetail.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomizeCard from "./CustomizeCard";
import { connctDB } from "../ConnctDB";
import { useLiveQuery } from "dexie-react-hooks";
import { v4 as uuid } from "uuid";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useLocationContext } from "./LocationInterface";

const Productdetail = () => {
  const { slug } = useParams();
  // const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [cart, setCart] = useState([]); // Shopping cart state
  const [quantity, setQuantity] = useState(1);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [customizeModalShow, setCustomizeModalShow] = useState(false);
  const [designAssistanceChecked, setDesignAssistanceChecked] = useState(false);
  const [activeTab, setActiveTab] = useState("home");

  const [isBasicCard, setIsBasicCard] = useState(false);

  const [imageHeight, setImageHeight] = useState(0);

  const imgRef = useRef(null);

  const { currencySymbol, priceType } = useLocationContext();

  useEffect(() => {
    const elementHeight = imgRef?.current?.offsetHeight;
    window.addEventListener("resize", () =>
      setImageHeight(imgRef?.current?.offsetHeight)
    );
    return () => {
      window.removeEventListener("resize", setImageHeight(elementHeight));
    };
  }, []);

  const cardsData = useLiveQuery(
    () =>
      connctDB.cart
        .where("cid")
        .equals(data?.id || 0)
        .toArray(),
    [data]
  );

  const addToCart = async (data, customized = false) => {
    const cardData = cardsData.find(
      (data) => data.designAssistanceChecked === !customized
    );
    const tempCardData = { ...cardData };
    if (Object.keys(tempCardData).length !== 0 && customized === false) {
      tempCardData.addedQuantity += quantity;
      tempCardData.designAssistanceChecked = !customized;
      await connctDB.cart.put(tempCardData);
    } else {
      // Change id to cid, and create unique id to separate customized and un-customized data
      await connctDB.cart.put({
        ...data,
        cid: data.id,
        id: uuid(),
        addedQuantity: +quantity,
        designAssistanceChecked: !customized,
      });
    }

    toast.success("Success! Product added to cart", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000, // 3000 milliseconds (3 seconds)
    });

    // **********
    // const existingCartItems = JSON.parse(localStorage.getItem("cart")) || [];
    // let itemExist = false;

    // for (const items of existingCartItems) {
    //   if (items.id === data.id) {
    //     items.addedQuantity += quantity;
    //     itemExist = true;
    //   }
    // }
    // if (itemExist === false)
    //   existingCartItems.push({ ...data, addedQuantity: quantity });
    // const cartItem = existingCartItems.find((item) => item.id === data.id);
    if (tempCardData) {
      tempCardData.designAssistanceChecked = designAssistanceChecked;
    }
    // localStorage.setItem("cart", JSON.stringify(existingCartItems));
    // window.dispatchEvent(new Event("storage"));

    // toast.success("Success! Product added to cart", {
    //   position: toast.POSITION.TOP_RIGHT,
    //   autoClose: 3000, // 3000 milliseconds (3 seconds)
    // });
    // **********

    // Find the item in the cart by its ID
    const existingItem = cart.find((item) => item.slug === slug);

    const quantityToAdd = parseInt(
      document.querySelector('input[name="addedQuantity"]').value,
      10
    );

    if (existingItem) {
      // If the item is already in the cart, update its quantity
      existingItem.quantity += quantityToAdd;
      setCart([...cart]); // Update the cart state
    } else {
      // If the item is not in the cart, add it as a new item
      const newItem = {
        id: parseInt(data?.id, 10),
        name: data.name,
        price: data[priceType],
        quantity: quantityToAdd || 1, // Use the input quantity or default to 1
      };
      setCart([...cart, newItem]); // Add the new item to the cart state
    }
  };
  const handleDesignAssistanceCheckbox = () => {
    const newDesignAssistanceChecked = !designAssistanceChecked;
    setDesignAssistanceChecked(newDesignAssistanceChecked);

    // Update local storage
    localStorage.setItem("DesignAssistance", newDesignAssistanceChecked);

    // If you have a backend API, you can make an API call here
    // updateDesignAssistance(newDesignAssistanceChecked);
  };

  useEffect(() => {
    const fetchProduct = () => {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/v1/api/public-api/list-products`
        )
        .then((res) => {
          const selectedCard = res.data.cards.find(
            (card) => card.slug === slug
          );
          if (selectedCard) {
            setData(selectedCard);
            if (selectedCard?.price <= 1000) setIsBasicCard(true);
            else setIsBasicCard(false);
          } else {
            console.error("Product not found");
          }
        })
        .catch((err) => {
          console.error("Error fetching product details:", err);
        });
    };

    fetchProduct();
  }, [slug]);

  if (data === null) {
    return <div>Loading...</div>;
  }

  const descriptionParagraphs = data?.description
    ?.split("\n")
    ?.map((paragraph, index) => <p key={index}>{paragraph}</p>);

  const imageSources = [
    `https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${data.image}`,
    "URL_OF_GLOSSY_BLACK_CIRCLE_IMAGE", // Replace with the actual URL
    "URL_OF_GLOSSY_BLACK_CIRCLE_IMAGE", // Replace with the actual URL
  ];

  const sortImages = () => {
    const imgList = [];
    if (data?.image) imgList.push(data?.image);
    data?.images?.forEach((image) => {
      const imgSplitArray = image.split(".");
      if (imgSplitArray[1] === "gif") imgList.unshift(image);
      else imgList.push(image);
    });
    return imgList;
  };

  return (
    <Container style={{ marginTop: "140px" }}>
      <Row style={{ padding: 0 }}>
        <Col
          xs={2}
          lg={1}
          style={{
            maxHeight: imageHeight || imgRef?.current?.offsetHeight,
            padding: 0,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              padding: 0,
              overflow: "scroll",
              height: "100%",
            }}
            className="scrollable-images"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "5px",
                height: "100%",
              }}
            >
              <>
                {sortImages().map((image, index) => (
                  <img
                    src={`https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${image}`}
                    alt={"img"}
                    key={index}
                    className="img-selector"
                    style={{
                      width: "100%",
                      // height: "100%",
                      // margin: "7px 0",
                      borderRadius: "15px",
                      border: "2px solid lightgray",
                    }}
                    onClick={() => {
                      setSelectedMedia(
                        `https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${image}`
                      );
                      setIsVideo(false);
                    }}
                  />
                ))}
              </>
            </div>
          </div>
        </Col>
        <Col
          xs={10}
          lg={5}
          style={{
            padding: "0 10px",
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
          }}
        >
          <div className="img-container" style={{ margin: "0" }}>
            {isVideo === false ? (
              <img
                src={selectedMedia || imageSources[0]}
                alt={data.name}
                // className="img-fluid"
                style={{ width: "100%" }}
                ref={imgRef}
              />
            ) : (
              <video controls autoPlay className="img-fluid">
                <source src={selectedMedia} type="video/mp4" />
              </video>
            )}
          </div>
        </Col>
        <Col xs={12} lg={6} className="mt-3 mt-md-0">
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
            }}
          >
            <div>
              <h2 style={{ fontWeight: 600, fontSize: "46px" }}>
                {data.name
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")}
              </h2>
              <h3 style={{ fontSize: "26px", fontWeight: 500 }}>
                A Classic Matte Finish Black Card
              </h3>
            </div>
            <div>
              <div>
                <h3
                  style={{
                    display: "inline",
                    marginRight: "10px",
                    fontSize: "52px",
                  }}
                >
                  {`${currencySymbol} ${data[priceType]}`}
                </h3>
                <span
                  style={{
                    fontSize: "28px",
                    color: "#404040	",
                    textDecoration: "underline",
                  }}
                >
                  35% off
                </span>
              </div>
              <p
                className="shipping-text"
                style={{ margin: 0, fontSize: "12px" }}
              >
                Shipping calculated at checkout.*
              </p>
            </div>
            <div>
              <h4
                style={{ margin: "0", padding: "10px 0 0 0", fontSize: "14px" }}
              >
                Reviews (128)
              </h4>
              <div class="star-rating">
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star"></i>
                <i class="fas fa-star-half-alt"></i>
              </div>
            </div>
            {/* <div className="d-flex flex-nowrap">
              <div
                className="product-link"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => {
                  setSelectedMedia(null);
                  navigate("/product-detail/minimalistic-white");
                }}
              >
                <img
                  src={require("../assets/images/white circle.png")}
                  className="img-fluid"
                  style={{ width: "40%", cursor: "pointer" }}
                  alt="Product 1"
                />
                <p
                  style={{
                    fontSize: "10px",
                    fontWeight: "700",
                    marginBottom: 0,
                  }}
                >
                  Minimalistic White
                </p>
              </div>

              <div
                className="product-link"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => {
                  setSelectedMedia(null);
                  navigate("/product-detail/gloss-black");
                }}
              >
                <img
                  src={require("../assets/images/glossyblackcircle.png")}
                  className="img-fluid"
                  style={{ width: "40%", cursor: "pointer" }}
                  alt="Product 2"
                />
                <p
                  style={{ fontSize: "10px", fontWeight: 700, marginBottom: 0 }}
                >
                  Gloss Black
                </p>
              </div>

              <div
                className="product-link"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={() => {
                  setSelectedMedia(null);
                  navigate("/product-detail/classic-matte-black");
                }}
              >
                <img
                  src={require("../assets/images/glossyblackcircle.png")}
                  className="img-fluid"
                  style={{ width: "40%", cursor: "pointer" }}
                  alt="Product 3"
                />
                <p
                  style={{ fontSize: "10px", fontWeight: 700, marginBottom: 0 }}
                >
                  Matte Black
                </p>
              </div>
            </div> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 0 15px 5px",
                fontSize: "12px",
                fontWeight: 700,
              }}
            >
              <input
                type="checkbox"
                id="designAssistanceCheckbox"
                checked={designAssistanceChecked}
                onChange={handleDesignAssistanceCheckbox}
                className="checkbox-large"
                disabled={isBasicCard}
              />
              <label
                htmlFor="designAssistanceCheckbox"
                className="checkbox-label"
                style={isBasicCard ? { color: "#aaa" } : { color: "#000" }}
              >
                If you need any design assistance, click this checkbox
              </label>
            </div>
            <div className="row last-part">
              <>
                <div className="col-lg-4 mb-3 mb-sm-2 mb-md-3">
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button
                        style={{ borderRadius: "10px 0 0 10px" }}
                        class="input-group-text"
                        onClick={() =>
                          quantity > 1 && setQuantity((prev) => prev - 1)
                        }
                      >
                        -
                      </button>
                    </div>
                    <input
                      class="form-control"
                      value={quantity}
                      type="number"
                      className="form-control"
                      name="addedQuantity"
                      placeholder="1"
                      min="1"
                      onChange={(e) => {
                        setQuantity(parseInt(e.target.value));
                      }}
                      style={{ textAlign: "center" }}
                    />
                    <div class="input-group-append">
                      <button
                        class="input-group-text"
                        style={{ borderRadius: "0 10px 10px 0" }}
                        onClick={() => setQuantity((prev) => prev + 1)}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  {/* <div className="input-group mb-3">
                    <div>
                      <button
                        className="input-group-text"
                        style={{ marginLeft: "0" }}
                      >
                        -
                      </button>
                    </div>
                    <input
                      value={quantity}
                      type="number"
                      className="form-control"
                      name="addedQuantity"
                      placeholder="1"
                      min="1"
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                      style={{ marginLeft: "0", width: "20px" }}
                    />
                  </div>
                  <div>
                    <button
                      className="input-group-text"
                      style={{ marginLeft: "0" }}
                    >
                      +
                    </button>
                  </div> */}
                </div>

                <div className="col-lg-4  mb-3 mb-sm-2 mb-md-3">
                  {/* <button
                    className="btn btn-3 w-100"
                    onClick={() => setModalShow(true)}
                    style={{ backgroundColor: "#f5db7d" }}
                    disabled={designAssistanceChecked} // Disable the button if checkbox is checked
                  >
                    Customize
                  </button>
                  <CustomizeCardModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    data={data}
                    // addCustomCardData={addCustomCardData}
                    addToCart={addToCart}
                    designAssistanceChecked={designAssistanceChecked}
                    handleDesignAssistanceCheckbox={
                      handleDesignAssistanceCheckbox
                    }
                  /> */}
                  <button
                    className="btn btn-3 w-100"
                    onClick={() => setCustomizeModalShow(true)}
                    style={{ backgroundColor: "#f5db7d" }}
                    disabled={designAssistanceChecked} // Disable the button if checkbox is checked
                  >
                    Customize
                  </button>
                  <CustomizeCard
                    show={customizeModalShow}
                    onHide={() => setCustomizeModalShow(false)}
                    data={data}
                    // addCustomCardData={addCustomCardData}
                    addToCart={addToCart}
                    designAssistanceChecked={designAssistanceChecked}
                    handleDesignAssistanceCheckbox={
                      handleDesignAssistanceCheckbox
                    }
                    isBasicCard={isBasicCard}
                  />
                </div>
                <div className="col-lg-4 ">
                  <button
                    className="btn btn-primary fsmall mx-auto w-100 w-lg-100"
                    onClick={() => addToCart(data)}
                    style={{ fontSize: "15px", borderRadius: "7px" }}
                  >
                    Add To Cart
                  </button>
                </div>
              </>
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <Row>
        <section className="faq-content">
          <div className="row">
            <div class="col-lg-9">
              <ul
                class="nav nav-tabs"
                id="myTab"
                role="tablist"
                style={{ marginTop: "4px" }}
              >
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Description
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    FAQ
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#contact"
                    type="button"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                  >
                    Design Process
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "home" ? "show active" : ""
                  }`}
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <br />
                    {descriptionParagraphs}
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "profile" ? "show active" : ""
                  }`}
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            style={{ fontSize: "18px" }}
                          >
                            DOES THE OTHER PERSON NEED THE CARD OR AN APP FOR
                            YOU TO SHARE INFORMATION?
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse show"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p style={{ fontSize: "18px", color: "black" }}>
                              No, The other person doesn’t have to have the card
                              or the app. They just need a phone compatible with
                              Connct, which most phones usually are. But in the
                              rare case they don’t, just share your QR code to
                              send all your important info via your Connct
                              profile.{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            style={{ fontSize: "18px" }}
                          >
                            WHAT PHONES ARE COMPATIBLE WITH THE ELECTRONIC
                            BUSINESS CARD?
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingTwo"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p style={{ fontSize: "18px", color: "black" }}>
                              Almost all the latest android phones & iPhones
                              after 7 are compatible with Connct. But you can
                              check the list of devices{" "}
                              <a href="/compatible-devices"> here</a>, just to
                              be sure.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            style={{ fontSize: "18px" }}
                          >
                            CAN I GET A CUSTOM BUSINESS CARD DESIGN FOR MY
                            COMPANY?
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingThree"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <p style={{ fontSize: "18px", color: "black" }}>
                              Absolutely ! We have an in-house designer that
                              will take care of all the customization for your
                              Connct card as per your brand guidelines. You can
                              check our range of custom cards{" "}
                              <a href="/product-list">here</a>.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsefour"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            style={{ fontSize: "18px" }}
                          >
                            HOW DO I ACTIVATE MY DIGITAL CARD?
                          </button>
                        </h2>
                        <div
                          id="collapsefour"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingFour"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <ul>
                              <li style={{ fontSize: "18px", color: "black" }}>
                                Go to Settings.{" "}
                              </li>
                              <li style={{ fontSize: "18px", color: "black" }}>
                                Click on “Activate account”.
                              </li>
                              <li style={{ fontSize: "18px", color: "black" }}>
                                Enter the activation key [ You will receive it
                                in your email / you will get the activation key
                                with your Connct Card].{" "}
                              </li>
                              <li style={{ fontSize: "18px", color: "black" }}>
                                Now to go “Link card” , Click on “Link Now” ,
                                Place your Connct card behind the phone till you
                                get the success message.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "contact" ? "show active" : ""
                  }`}
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <div class="accordion" id="accordionExample">
                    <br />
                    {slug.startsWith("basic") ? (
                      <p style={{ textAlign: "left" }}>
                        The Basic option allows you to include text-only
                        information on your physical card. Follow these simple
                        steps to design your Standard card:
                        <br />
                        <br />
                        1. Select your preferred card type.
                        <br />
                        <br />
                        2. Click on "Customize" to begin personalizing.
                        <br />
                        <br />
                        3. Enter your desired text into the designated field.
                        <br />
                        <br />
                        4. Choose your preferred font style, weight, and size,
                        then click "Save."
                        <br />
                        <br />
                        5. Please note that while the physical business card has
                        text-only capabilities, our digital business card offers
                        full customization through our app.
                      </p>
                    ) : slug.startsWith("custom") ? (
                      <p style={{ textAlign: "left" }}>
                        Our fully customized cards offer unparalleled
                        flexibility, allowing you to tailor every aspect of your
                        card to your unique brand identity. Here's how to design
                        your fully customized card:
                        <br />
                        <br />
                        1. Select your preferred card type.
                        <br />
                        <br />
                        2. Click on "Customize" to begin personalizing.
                        <br />
                        <br />
                        3. Customize Text and Graphics: Easily add your company
                        logo, name, title, and other details using our intuitive
                        tools. Adjust font style, size, color, and positioning
                        to your preference.
                        <br />
                        <br />
                        4. Incorporate Brand Colors: Stand out by using your
                        brand's colors. Choose from a variety of options to
                        maintain consistency with your brand's visual identity.
                        <br />
                        <br />
                        5. Add Images or Graphics: Enhance your card with
                        relevant images, graphics, or icons. Upload your own or
                        select from our library of high-quality graphics.
                        <br />
                        <br />
                        6. Review and Finalize: Take a moment to review your
                        design for accuracy and completeness. Make any necessary
                        adjustments before finalizing your card.
                        <br />
                        <br />
                        7. Save and Order: Save your design and proceed to order
                        your fully customized cards.
                        <br />
                        <br />
                        8. If you need assistance with the design, click on the
                        check box in the product or customization panel and
                        place the order. Our Design executive will reach out to
                        you, design the card, and print it upon your approval
                      </p>
                    ) : slug.startsWith("professional") ? (
                      <p style={{ textAlign: "left" }}>
                        Our Professional card offers various templates to choose
                        from and customize it with text-only information.
                        <br />
                        <br />
                        Here's how to design your Professional card:
                        <br />
                        <br />
                        1. Choose Your Design Template: Start by selecting a
                        design template that best reflects your brand's
                        aesthetic and personality.
                        <br />
                        <br />
                        2. Customize Text and Graphics: Add your company logo,
                        name, title, and any other relevant information using
                        our intuitive customization tools. You can adjust the
                        font style, size, color, and positioning to your liking.
                        <br />
                        <br />
                        3. Review and Finalize: Take a moment to review your
                        design for accuracy and completeness. Make any necessary
                        adjustments before finalizing your card.
                        <br />
                        <br />
                        4. Save and Place your order.
                        <br />
                        <br />
                        5. If you want to further customize the card with your
                        logo and Graphics, please choose the Customized card
                        option
                      </p>
                    ) : (
                      <p>Default Design Process</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Row>
    </Container>
  );
};

export default Productdetail;
