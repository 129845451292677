import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Ambassador.css';

const Ambassador = () => {
  return (
    <div className="custom-container">
      <Container className="custom-container-inner" style={{marginTop:'140px'}}>
        <h3 style={{fontWeight:'bold'}}>Become An Ambassador</h3><br />
        <hr className="lightgray-lines" style={{ marginTop: '3px' }} /> <br /><br />
        <Row>
          <Col md={6} className="custom-text-col">
            {/* Left Column: Text Content */}
            <div className="custom-text-content">
              <h3 className="fw-800 c-primary" style={{fontWeight:'bold'}}>Connect with us & earn by referral</h3>
              <h6 style={{marginTop:'22px',fontWeight:'600'}}>Get started with the ambassador program in three easy steps:</h6>
              <ul className="circle-bullet" style={{marginTop:'20px'}}>
                <li><FontAwesomeIcon icon={faCheckCircle} className="check-icon" style={{color: '#FAAD3D'}} /> Fill out the short form.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="check-icon" style={{color: '#FAAD3D'}}/> Tell us why you find Connct better than traditional business cards.</li>
                <li><FontAwesomeIcon icon={faCheckCircle} className="check-icon" style={{color: '#FAAD3D'}}/> Be a part of our team.</li>
              </ul>
              <p className="mt-4" style={{fontSize:'19px',fontWeight:'1100'}}><b>Join the team as an ambassador today and share with everyone to earn passive income.</b></p>
              <a href="/contact" className="df" type="button">Contact Us</a>
            </div>
          </Col>
          <Col md={6} className="custom-image-col">
            {/* Right Column: Image */}
            <div className="custom-image-content">
              <img
                className="img-fluid mx-auto d-block custom-image"
                src={require('../assets/images/improve-networking-ROI.png')}
                alt="Set your team with the most-advanced digital visiting card"
              />
            </div>
          </Col>
        </Row>
        <div className="col-sm-8 mx-auto" style={{marginTop:'95px',textAlign:'center'}}>
                  <h3 className="fw-bold" style={{fontSize:'32px'}}>Easily <span style={{color:'rgba(4, 69, 157, 1)'}}>Shareable link</span>. Commission <span
                    style={{color:'#FAAD3D'}}>upto 25% </span>  on every purchase. <span  style={{color:'rgba(4, 69, 157, 1)'}}>No limit
                    profiles</span>  on earnings.</h3>
                </div><br/><br/><br/>
      </Container>
    </div>
  );
};

export default Ambassador;
