// utils/auth.js

import jwt_decode from "jwt-decode";

export function isTokenValid(token) {
  if (!token) {
    return false;
  }

  try {
    const decoded = jwt_decode(token);
    // Check if the token has expired
    return decoded.exp * 1000 > Date.now(); // Exp is in seconds, so multiply by 1000 to get milliseconds
  } catch (error) {
    return false;
  }
}
