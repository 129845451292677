import React, { useState, useEffect } from "react";
import axios from "axios";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import { Row, Col } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import "line-awesome/dist/line-awesome/css/line-awesome.min.css";
import { Helmet } from "react-helmet";
import "./Herosection.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { useLocationContext } from "./LocationInterface";

const Herosection = () => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const { currencySymbol, priceType } = useLocationContext();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/v1/api/public-api/list-products`)
      .then((response) => {
        setProducts(response.data.cards);
        // Set the default selected product here
        if (response.data.cards.length > 0) {
          setSelectedProduct(response.data.cards[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      });
    AOS.init();
  }, []);

  if (!Array.isArray(products)) {
    console.error(
      "Products data is not in the expected array format:",
      products
    );
    return <div>Products data is not in the expected format.</div>;
  }

  const displayedProducts = products.slice(0, 3);

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCardClick = () => {
    if (selectedProduct) {
      // Redirect to the product details page for the selected product
      window.location.href = `/product-detail/${selectedProduct.slug}`;
    }
  };

  // Construct the complete image URL
  const getImageUrl = (imagePath) => {
    return `https://${process.env.REACT_APP_AWS_S3_BUCKET_URL}.s3.ap-south-1.amazonaws.com/${imagePath}`;
  };
  return (
    <div className="home-page">
      <Helmet>
        <title>
          Connct.ai Digital NFC Business Cards. Revolutionize Networking
        </title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
        <meta name="robots" content="index,follow"></meta>
        <meta
          name="facebook-domain-verification"
          content="76mxtqr8maf4jd7publr59pn5stjur"
        ></meta>
        <meta
          name="google-site-verification"
          content="VS9tF3UvpS-8c0O9z1jclypU0SBa_rFQKlzDZ5TmEKM"
        ></meta>
        <meta
          name="description"
          content="Upgrade your networking with Connct.ai's Digital NFC Business Cards. Stay connected effortlessly and make lasting impressions."
        />
      </Helmet>

      <div>
        <section className="hero-banner">
          <div className="container" style={{ marginTop: "90px" }}>
            <div className="wrap">
              <div className="row">
                <div className="col-12">
                  <h1
                    className="text-black"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    The most powerful business card you will ever own!
                  </h1>
                  <div className="tagline" style={{ marginTop: "10px" }}>
                    <span
                      className="color-primary fw-500 d-inline-block pb-3"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      style={{
                        color: "rgba(4, 69, 157, 1)",
                        fontWeight: "500",
                      }}
                    >
                      Paperless<strong>.</strong>
                    </span>
                    &nbsp;
                    <span
                      className="color-secondary fw-500 d-inline-block pb-3"
                      data-aos="fade-up"
                      data-aos-delay="200"
                      style={{ color: "#F9D572", fontWeight: "500" }}
                    >
                      Seamless<strong>.</strong>
                    </span>
                    &nbsp;
                    <span
                      className="fw-500 d-inline-block pb-3"
                      data-aos="fade-up"
                      data-aos-delay="250"
                      style={{ fontWeight: "500" }}
                    >
                      Timeless<strong>.</strong>
                    </span>
                  </div>
                  <div id="ctza" className="cta">
                    <Link
                      to="/product-list"
                      state={{ toPopular: "true" }}
                      className="btn btn-secondary top-btn"
                      style={{ fontWeight: "500" }}
                    >
                      Click to Connect
                      <span className="hand-shake">👋</span>
                    </Link>
                    <img
                      className="header-group-img"
                      src={require("../assets/images/header-img1.png")}
                      alt="Header"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="bg-blue" style={{ backgroundColor: "#F7F8FD" }}>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              {/* Content for the left column */}
              <h2
                className="color-primary"
                style={{
                  color: "rgba(4, 69, 157, 1)",
                  marginTop: "70px",
                  marginLeft: "20px",
                  fontWeight: "bold",
                }}
              >
                Share all your contact details & more
                <br /> in three easy steps:
              </h2>
              <div className="row">
                <div
                  className="col-12"
                  style={{ marginTop: "20px", marginLeft: "11px" }}
                >
                  <div data-aos="fade-up">
                    <div className="step">
                      <div className="icon">
                        <i
                          className="las la-mobile"
                          style={{ fontSize: "39px" }}
                        ></i>{" "}
                        {/* Mobile Icon */}
                      </div>
                      <div
                        className="step-content"
                        style={{ marginLeft: "8px" }}
                      >
                        <h5 style={{ marginTop: "0px" }}>Download the app</h5>
                        <p
                          className="mb-0"
                          style={{ fontSize: "16px", color: "black" }}
                        >
                          Android, ios, we are everywhere!
                        </p>
                        <br />
                        <div className="download-app">
                          <a
                            // href="https:apps.apple.com/app/connct-app/id1625064091"
                            href="https://apps.apple.com/us/app/connct-app/id1625064091"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require("../assets/images/appstore.png")}
                              className="img-fluidd"
                              width="120"
                              alt="Play Store"
                            />
                          </a>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.connct.waves_app"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require("../assets/images/playstore.png")}
                              className="img-fluidd"
                              width="120"
                              alt="App Store"
                              style={{ marginLeft: "19px" }}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div data-aos="fade-up" data-aos-delay="100">
                    <div className="step">
                      <div className="icon">
                        <i
                          className="las la-address-card"
                          style={{ fontSize: "39px" }}
                        ></i>{" "}
                        {/* Profile Icon */}
                      </div>
                      <div
                        className="step-content"
                        style={{ marginLeft: "8px" }}
                      >
                        <h5 style={{ marginTop: "0px" }}>
                          Create your profile
                        </h5>
                        <p style={{ fontSize: "16px", color: "black" }}>
                          Showcase your business by adding your contact details,{" "}
                          <br />
                          social media & other important business info.
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div data-aos="fade-up" data-aos-delay="150">
                    <div className="step">
                      <div className="icon">
                        <i
                          className="las la-link"
                          style={{ fontSize: "39px" }}
                        ></i>{" "}
                        {/* Link Icon */}
                      </div>
                      <div
                        className="step-content"
                        style={{ marginLeft: "8px" }}
                      >
                        <h5 style={{ marginTop: "0px" }}>
                          Link your profile to the digital business card
                        </h5>
                        <p style={{ fontSize: "16px", color: "black" }}>
                          Connect your profile with the ultimate digital
                          business card <br />& be one-tap ready
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={6}>
              {/* Content for the right column */}
              <div
                className="image-containerr"
                data-aos="fade-up"
                data-aos-delay="100"
                style={{ marginTop: "40px" }}
              >
                <img
                  src={require("../assets/images/card tap gray gif 2.gif")}
                  alt="Track Connections"
                  style={{ width: "90%", marginLeft: "50px" }}
                  className="gifimage"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section info-text" style={{ marginTop: "60px" }}>
        <div className="container">
          <div className="col-sm-7 mx-auto">
            <h3
              className="text-center fw-800 mb-0"
              data-aos="fade-up"
              style={{ fontWeight: "700", fontSize: "29px" }}
            >
              Add{" "}
              <span
                className="color-primary"
                style={{ color: "rgba(4, 69, 157, 1)" }}
              >
                Unlimited
              </span>{" "}
              Information.&nbsp;{" "}
              <span className="color-secondary" style={{ color: "#FAAD3D" }}>
                No apps
              </span>{" "}
              required to receive.&nbsp;{" "}
              <span className="color-secondary" style={{ color: "#FAAD3D" }}>
                Secured
              </span>{" "}
              data sharing.
            </h3>
          </div>
        </div>
      </section>

      <br />

      <Container>
        <div className="col-md-12">
          <div
            className="product-card"
            onClick={handleCardClick}
            style={{ cursor: "pointer" }}
          >
            {selectedProduct && (
              <div
                className="product-card-content mx-auto text-center" // Added mx-auto and text-center
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "90%",
                  maxWidth: "29cm",
                  border: "0.1em solid grey", // Decreased border size
                  padding: "1cm",
                  borderRadius: "20px",
                  maxHeight: "auto",
                  boxSizing: "border-box",
                }}
              >
                <div className="product-image" style={{ flex: 1 }}>
                  <Card.Img
                    src={getImageUrl(selectedProduct.image)}
                    alt={selectedProduct.name}
                    style={{
                      width: "80%",
                      height: "auto",
                      borderRadius: "16px",
                      marginLeft: "-144px",
                      whiteSpace: "nowrap",
                    }}
                  />
                </div>
                <div
                  className="product-details"
                  style={{ flex: 2, marginLeft: "1cm" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Card.Title
                      style={{
                        fontWeight: 800,
                        fontSize: "16px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {selectedProduct.name
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </Card.Title>
                    {selectedProduct.name === "Connct - gloss black" && (
                      <span
                        className="mostpop"
                        style={{
                          marginLeft: "390px",
                          backgroundColor: "#FFC107",
                          color: "white",
                          padding: "0.2rem 0.5rem",
                          borderRadius: "20px",
                          fontSize: "10px",
                          marginTop: "-19px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Most Popular
                      </span>
                    )}
                  </div>

                  <br />
                  <Card.Text style={{ textAlign: "left" }}>
                    {`${selectedProduct.name
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}'s styled card with a smooth elegant finish.
  The PVC business card comes with connct’s advanced NFC chip
  technology for one-tap wireless sharing and embedded QR
  code for instant sharing.`}
                  </Card.Text>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Card.Text style={{ fontSize: "19px", fontWeight: "800" }}>
                      {currencySymbol} {selectedProduct[priceType]}
                    </Card.Text>
                    <Button variant="primary" style={{ marginLeft: "1cm" }}>
                      Customize
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="image-container">
          <div onClick={() => handleProductClick(displayedProducts[0])}>
            <img
              src={require("../assets/images/white circle.png")}
              className="img-fluid"
              style={{ width: "70%", maxWidth: "200px", cursor: "pointer" }}
              alt="Image1"
            />
          </div>
          <div onClick={() => handleProductClick(displayedProducts[1])}>
            <img
              src={require("../assets/images/glossyblackcircle.png")}
              className="img-fluid"
              style={{ width: "70%", maxWidth: "200px", cursor: "pointer" }}
              alt="Image2"
            />
          </div>
          <div onClick={() => handleProductClick(displayedProducts[2])}>
            <img
              src={require("../assets/images/glossyblackcircle.png")}
              className="img-fluid"
              style={{
                width: "70%",
                maxWidth: "200px",
                cursor: "pointer",
              }}
              alt="Image3"
            />
          </div>
        </div>
      </Container>

      <div className="home-container">
        {/* Use Link to create a link to the product list */}
        <Link to="/product-list" state={{ toPopular: "true" }}>
          <button
            className="see-all-buttony"
            style={{ marginRight: "160px", whiteSpace: "nowrap" }}
          >
            See All Products
          </button>
        </Link>
      </div>

      <section className="mt-4 section" style={{ backgroundColor: "#FCFCFD" }}>
        <div className="container">
          <div className="row profile-details justify-content-center">
            <div className="col-md-8 text-center">
              {" "}
              {/* Use col-md-8 to set a maximum width */}
              <div className="mx-auto" style={{ marginTop: "80px" }}>
                <h2 data-aos="fade-up" className="text-center">
                  Share-Ready Profile within seconds
                </h2>

                <div
                  className="image-containerr"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <img
                    src={require("../assets/images/share-ready.gif")}
                    alt="Track Connections"
                    style={{ width: "70%" }}
                    className="gifimage"
                  />
                </div>

                <h4
                  data-aos="fade-up"
                  className="pt-2 text-center"
                  style={{ fontWeight: "400" }}
                >
                  <a href="/upgrade-to-pro">Upgrade</a> to the pro version for
                  the ultimate experience with Connct pro.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <Container>
          <Row>
            <Col md={6}>
              <div
                className="order-md-1 mb-4 mb-md-0 text-center"
                id="serasdac"
                style={{ marginTop: "20px" }}
              >
                <img
                  src={require("../assets/images/getting.gif")}
                  alt="Mobile Logo"
                  style={{ width: "50%", maxWidth: "200px" }}
                  className="klosdg"
                />
              </div>
            </Col>
            <Col
              md={6}
              className="align-center d-flex justify-content-center flex-column"
            >
              <div className="text-center">
                <h2
                  className="color-primary"
                  style={{
                    textAlign: "left",
                    color: "rgba(4, 69, 157, 1)",
                    fontWeight: "bold",
                  }}
                >
                  Track your connections in real-time
                </h2>
                <h5
                  style={{
                    textAlign: "left",
                    fontWeight: "500",
                    fontSize: "20px",
                  }}
                >
                  Real-time auto-saving ensures you never lose anyone you
                  connect with.
                </h5>
              </div>
              <div className="mt-4 text-left">
                <div className="text-icon-container" data-aos="fade-up">
                  <div
                    className="d-flex align-items-center"
                    style={{ marginTop: "-30px" }}
                  >
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#FAAD3D", paddingRight: "10px" }}
                    />
                    <h5 className="ml-2 mb-0" style={{ fontWeight: "400" }}>
                      Track your customers
                    </h5>
                  </div>
                </div>
                <br />
                <div className="text-icon-container" data-aos="fade-up">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#FAAD3D", paddingRight: "10px" }}
                    />
                    <h5 className="ml-2 mb-0" style={{ fontWeight: "400" }}>
                      Connect with prospects anytime
                    </h5>
                  </div>
                </div>
                <br />
                <div className="text-icon-container" data-aos="fade-up">
                  <div className="d-flex align-items-center">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ color: "#FAAD3D", paddingRight: "10px" }}
                    />
                    <h5 className="ml-2 mb-0" style={{ fontWeight: "400" }}>
                      Download & Integrate to CRM for conversion
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section info-text">
        <div className="container">
          <div className="col-sm-7 mx-auto" style={{ marginTop: "40px" }}>
            <h3
              className="text-center fw-800 mb-0"
              data-aos="fade-up"
              style={{ fontWeight: "800", fontSize: "29px" }}
            >
              Say goodbye to{" "}
              <span className="color-secondary" style={{ color: "#FAAD3D" }}>
                bulk business
              </span>{" "}
              cards. One tree worth paper{" "}
              <span
                className="color-primary"
                style={{ color: "rgba(4, 69, 157, 1)" }}
              >
                saved every time
              </span>{" "}
              you connect. Cards prospects can't lose.
            </h3>
          </div>
        </div>
      </section>

      <div
        style={{
          backgroundColor: "#04459D",
          position: "relative",
          marginTop: "150px",
        }}
        id="dianc"
      >
        <div style={{ height: "8cm" }}>
          <Container className="d-md-flex justify-content-md-between align-items-center h-100">
            <div
              className="order-md-1 text-md-start"
              style={{ color: "white" }}
            >
              <h2 className="fw-bold">Share your profile with a Link</h2>
              <ul style={{ lineHeight: "1.6rem", paddingInlineStart: "1rem" }}>
                <li data-aos="fade-up">
                  Update your email signature with your custom URL
                </li>
                <li data-aos="fade-up">Share with anyone as a text</li>
                <li data-aos="fade-up">Add to your website</li>
              </ul>
            </div>
            <div className="order-md-2 mb-4 mb-md-0 text-center" id="herlac">
              <img
                src={require("../assets/images/Group 1944.png")}
                alt="Mobile Logo"
                style={{ width: "80%", maxWidth: "500px" }}
                className="klog"
              />
            </div>
          </Container>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      <div
        style={{
          backgroundColor: "#04459D",
          position: "relative",
          marginTop: "60px",
        }}
        id="mainc"
      >
        <div style={{ height: "8cm" }}>
          <Container className="d-md-flex flex-md-row justify-content-md-between align-items-center h-100">
            <div
              className="order-md-2 text-left text-md-start"
              style={{ color: "white" }}
            >
              <h2 className="fw-bold">Share your profile with QR code</h2>
              <ul style={{ lineHeight: "1.6rem", paddingInlineStart: "1rem" }}>
                <li data-aos="fade-up">
                  For older phones without NFC share your profile with QR{" "}
                </li>
                <li data-aos="fade-up">Add in zoom meeting background</li>
                <li data-aos="fade-up">Download & Display anywhere</li>
              </ul>
            </div>
            <div className="order-md-1 mb-4 mb-md-0 text-center" id="serac">
              <img
                src={require("../assets/images/qr code scanning gif.gif")}
                alt="Mobile Logo"
                style={{ width: "70%", maxWidth: "300px" }}
                className="klog"
              />
            </div>
          </Container>
        </div>
      </div>

      <div
        style={{ backgroundColor: "rgba(20, 20, 20, 1)", marginTop: "220px" }}
        id="nines"
      >
        <section
          className="section user-types"
          style={{ backgroundColor: "rgba(20, 20, 20, 1)" }}
        >
          <div className="container slider-section">
            <div className="row">
              <div className="col-md-2" id="curve">
                <img
                  className="img-fluyid first-img"
                  src={require("../assets/images/bottom-curved-image.png")}
                  alt=""
                  style={{ marginTop: "-20px" }}
                />
              </div>
              <div
                className="col-md-10"
                id="secu"
                style={{ marginTop: "-110px" }}
              >
                <div className="row left-img">
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="img-block">
                      <div
                        className="image-containser"
                        style={{ borderRadius: "10px" }}
                      >
                        <img
                          className="img-fluid zoom-on-hover"
                          src={require("../assets/images/professionals.png")}
                          alt="The modern professional’s choice of sharing information"
                        />
                        <div className="img-caption">
                          The modern professional’s choice of sharing
                          information
                        </div>
                      </div>
                    </div>
                    <h2 style={{ color: "lightgray", fontSize: "31px" }}>
                      .Professionals
                    </h2>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <div className="img-block">
                      <div
                        className="image-containser"
                        style={{ borderRadius: "10px" }}
                      >
                        <img
                          className="img-fluid zoom-on-hover"
                          src={require("../assets/images/freelancer.png")}
                          alt="Share your portfolio, social links & more with your potential clients"
                        />
                        <div className="img-caption">
                          Share your portfolio, social links & more with your
                          potential clients
                        </div>
                      </div>
                    </div>
                    <h6 style={{ color: "lightgray", fontSize: "29px" }}>
                      .Freelancer
                    </h6>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="img-block">
                      <div
                        className="image-containser"
                        style={{ borderRadius: "10px" }}
                      >
                        <img
                          className="img-fluid zoom-on-hover"
                          src={require("../assets/images/events.jpg")}
                          alt="Extract the full potential of networking at your event with the ultimate digital visiting card"
                          style={{ borderRadius: "10px" }}
                        />
                        <div className="img-caption">
                          Extract the full potential of networking at your event
                          with the ultimate digital visiting card.
                        </div>
                      </div>
                    </div>
                    <h6 style={{ color: "lightgray", fontSize: "29px" }}>
                      .Events
                    </h6>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="img-block">
                      <div
                        className="image-containser"
                        style={{ borderRadius: "10px" }}
                      >
                        <img
                          className="img-fluid zoom-on-hover"
                          src={require("../assets/images/founders.jpg")}
                          alt="The modern professional’s choice of sharing information"
                          style={{ borderRadius: "10px" }}
                        />
                        <div className="img-caption">
                          Share all your business information with your
                          potential clients, investors & all the stakeholders.
                        </div>
                      </div>
                    </div>
                    <h6 style={{ color: "lightgray", fontSize: "29px" }}>
                      .Founders
                    </h6>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="img-block">
                      <div
                        className="image-containser"
                        style={{ borderRadius: "10px" }}
                      >
                        <img
                          className="img-fluid zoom-on-hover"
                          style={{ borderRadius: "10px" }}
                          src={require("../assets/images/content-creators.jpg")}
                          alt="Showcase your social links & your proof of work and score potential brand deals"
                        />
                        <div className="img-caption">
                          Showcase your social links & your proof of work and
                          score potential brand deals.{" "}
                        </div>
                      </div>
                    </div>
                    <h6 style={{ color: "lightgray", fontSize: "29px" }}>
                      .Content Creators
                    </h6>
                  </div>
                  <div
                    className="col-md-4 mb-4"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="img-block">
                      <div
                        className="image-containser"
                        style={{ borderRadius: "10px" }}
                      >
                        <img
                          className="img-fluid zoom-on-hover"
                          style={{ borderRadius: "10px" }}
                          src={require("../assets/images/retail.jpg")}
                          alt="Share your business info with all your walk-ins with Connct NFC card"
                        />
                        <div className="img-caption">
                          Share your business info with all your walk-ins with
                          Connct NFC card.
                        </div>
                      </div>
                    </div>
                    <h6 style={{ color: "lightgray", fontSize: "29px" }}>
                      .Retail
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section style={{ backgroundColor: "rgba(20, 20, 20, 1)" }}>
          <div className="container slider-section2">
            <div className="text-len" data-aos="fade-up">
              <h2 style={{ fontWeight: "700" }}>Let’s hear what people say</h2>
            </div>

            <div className="row">
              <div
                className="col-md-6 mb-3"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <img
                  className="img-fluid mx-auto d-block"
                  src={require("../assets/images/client-testmonial.jpg")}
                  alt="Client Testimonial"
                />
              </div>
              <div
                className="col-md-6"
                data-aos="fade-up"
                style={{ marginTop: "-40px", marginLeft: "-12px" }}
              >
                <h4 style={{ fontWeight: "500", fontSize: "21px" }}>
                  Great Design & Solution!
                </h4>
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "500",
                    marginTop: "-15px",
                    lineHeight: "1.7rem",
                  }}
                >
                  Easy to use and the best way to share your information with
                  your potential client. It has worked great when I had to share
                  information with them
                </p>
                <div className="row bottom-part">
                  <div className="col-sm-2">
                    <img
                      className="img-fludid mx-auto d-block"
                      src={require("../assets/images/testi-user-pic.png")}
                      alt="User Pic"
                    />
                  </div>
                  <div className="col-sm-10">
                    <h5 style={{ fontSize: "19px", color: "white" }}>Emad</h5>
                    <p className="small">Entrepreneur</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Herosection;
