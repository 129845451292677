import "./Blogs.css";

export const SocialLink = ({
  children,
  color,
  targetLink,
  dataAction = "",
}) => {
  return (
    <a
      href={targetLink}
      target="_blank"
      className="icon-btn"
      style={{ backgroundColor: color }}
      data-action={dataAction}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
