import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Navbar from "./Components/Navbar";
import Herosection from "./Components/Herosection";
import Footer from "./Components/Footer";
import Pro from "./Components/Pro";
import Enterprise from "./Components/Enterprise";
import Faq from "./Components/Faq";
import Contact from "./Components/Contact";
import Term from "./Components/Term";
import Privacy from "./Components/Privacy";
import Refund from "./Components/Refund";
import ShippingPolicy from "./Components/ShippingPolicy";
import Compatible from "./Components/Compatible";
import Ambassador from "./Components/Ambassador";
import Productlist from "./Components/Productlist";
import Productdetail from "./Components/Productdetail";
import Login from "./Components/Login";
import Signup from "./Components/Signup";
import Forgotpassword from "./Components/Forgotpassword";
import Blogs from "./Components/Blogs";
import Pagenotfound from "./Components/Pagenotfound";
import Shoppingcart from "./Components/Shoppingcart";
import Downloadconnct from "./Components/Downloadconnct";
import BlogDetails from "./Components/BlogDetails";
import Billing from "./Components/Billing";
import ThankYou from "./Components/ThankYou";
import TawkChat from "./Components/TawkChat";
import "./App.css";
import ConfirmPassword from "./Components/ConfirmPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Router>
      <div className="App">
        <ToastContainer />
        {/* Conditionally render Navbar for routes other than login */}
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Outlet />
              </>
            }
          >
            <Route index element={<Herosection />} />
            <Route path="/" element={<Herosection />} />
            <Route path="/product-detail/:slug" element={<Productdetail />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogDetails />} />
            <Route path="/billing" element={<Billing />} />
            <Route path="upgrade-to-pro" element={<Pro />} />
            <Route path="enterprise" element={<Enterprise />} />
            <Route path="faq" element={<Faq />} />
            <Route path="contact" element={<Contact />} />
            {/* <Route path="blogs" element={<Blogs />} /> */}
            <Route path="terms" element={<Term />} />
            <Route path="privacy-policy" element={<Privacy />} />
            <Route path="refund-policy" element={<Refund />} />
            <Route path="shipping-policy" element={<ShippingPolicy/>} />
            <Route path="compatible-devices" element={<Compatible />} />
            <Route path="ambassador" element={<Ambassador />} />
            <Route path="product-list" element={<Productlist />} />
            <Route path="shoppingcart" element={<Shoppingcart />} />
            <Route path="download-connct" element={<Downloadconnct />} />
            <Route path="/thank-you" element={<ThankYou />} />      
            {/* Add a catch-all route for unmatched paths */}
            <Route path="*" element={<Pagenotfound />} />
          </Route>
          {/* For the login route, exclude the Navbar */}
          <Route path="login" element={<Login />} />
          <Route path="sign-up" element={<Signup />} />
          <Route path="forgot-password" element={<Forgotpassword />} />
          <Route path="confirm-password/:id" element={<ConfirmPassword />} />
        </Routes>

        {/* Conditional rendering for the Footer */}
        {window.location.pathname !== "/assets/images/card-gold.png" &&
          window.location.pathname !== "/assets/images/card-silver.png" && (
            <Footer />
          )}
        <TawkChat />
      </div>
    </Router>
  );
}

export default App;
