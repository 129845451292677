import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Basiccards from "./Basiccards";
import Customcards from "./Customcards";

const Productlist = () => {
  const { state } = useLocation();
  const [basicCards, setBasicCards] = useState([]);
  const [customCards, setCustomCards] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/v1/api/public-api/list-products`)
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
        if (data && data.cards && Array.isArray(data.cards)) {
          const basicCards = data?.cards?.filter((card, index) => {
            const cardType = card?.slug?.split("-")[0];
            return cardType === "basic";
          });
          const customCards = data?.cards?.filter((card, index) => {
            const cardType = card?.slug?.split("-")[0];
            return cardType === "custom";
          });
          setBasicCards(basicCards);
          setCustomCards(customCards);
        } else {
          console.error("Invalid data format:", data);
          setBasicCards([]);
          setCustomCards([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setBasicCards([]);
        setCustomCards([]);
      });
  }, []);

  const basicCardRef = useRef();
  const customCardRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (state?.dest === "basicCards") {
        basicCardRef.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (state?.dest === "customCards") {
        customCardRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  return (
    <div>
      <Basiccards ref={basicCardRef} basicCards={basicCards} />
      <Customcards ref={customCardRef} customCards={customCards} />
      <br />
      <br />
    </div>
  );
};

export default Productlist;
